import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Alert, Label, Col, Input, Row } from 'reactstrap';
import Loading from '../../components/Loading';
import { Navigate, useParams } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import ReturnButton from '../../components/ReturnButton';
import { useActor } from '../../storage/storage';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useFetchMetrics, useUpdateMetric } from '../../api/CompanyAPI';
import { DECIMAL_REGEXP } from '../../utils/constants';

const EditMetric = () => {
  const { id } = useParams();
  const actor = useActor();
  const { metrics, loading, error: fetchMetricsError, fetchMetrics } = useFetchMetrics();
  const { updateMetric } = useUpdateMetric();
  const [errorForm, setErrorForm] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    if (actor.company_id) fetchMetrics(actor.company_id);
  }, [fetchMetrics, actor.company_id]);

  useEffect(() => {
    if (metrics) {
      const selectedMetric = metrics.find((metric) => metric.id === id);
      if (!selectedMetric) return;
      setFormData({
        id: selectedMetric.id,
        name: selectedMetric.name,
        levels: {
          0: selectedMetric.levels['0'] || '',
          1: selectedMetric.levels['1'] || '',
          2: selectedMetric.levels['2'] || '',
        },
      });
    }
  }, [metrics, id]);

  const handleInputChange = (event) => {
    const { name, value, dataset } = event.target;
    if (name !== 'levels') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    const index = dataset.index;

    if (DECIMAL_REGEXP.test(value)) {
      setFormData((prevData) => ({
        ...prevData,
        levels: {
          ...prevData.levels,
          [index]: value,
        },
      }));
    } else {
      event.preventDefault();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const error = validateForm();
    if (error) {
      setErrorForm(error);
      return;
    }
    try {
      await updateMetric(actor.company_id, formData);
      setRedirect(true);
    } catch (error) {
      setErrorForm('Error submitting the form:', error.message);
    }
  };

  const validateForm = () => {
    if (!formData.name) return 'The name is required';
    if (!formData.levels[0]) return 'The bronze level is required';
    if (!formData.levels[1]) return 'The silver level is required';
    if (!formData.levels[2]) return 'The gold level is required';
    return null;
  };

  if (redirect) return <Navigate to="/metrics" />;
  if (loading) return <Loading />;

  const messageError = errorForm || fetchMetricsError;

  return (
    <>
      {messageError && <Alert color="danger">{messageError}</Alert>}
      {formData && (
        <>
          <h2 className="admin-text">Edit Metric {formData.name}</h2>
          <Form onSubmit={handleSubmit}>
            <Row>
              <FormGroup>
                <Label className="admin-text" for="name" sm={2}>
                  {t('form.name')}
                </Label>
                <Col sm={6}>
                  <Input id="name" name="name" value={formData.name} type="text" onChange={handleInputChange} />
                </Col>
              </FormGroup>
            </Row>
            <Row>
              {[t('game.catalogue.bronze'), t('game.catalogue.silver'), t('game.catalogue.gold')].map(
                (level, index) => (
                  <Col sm={2} key={level}>
                    <FormGroup>
                      <Label className="admin-text" for={`level-${index}`}>
                        {level}
                      </Label>
                      <Input
                        id={`level-${index}`}
                        name="levels"
                        data-index={index}
                        type="text"
                        value={formData.levels[index]}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                )
              )}
            </Row>
            <FormGroup row>
              <Col sm={6}>
                <ReturnButton to={'/metrics'} />
                <Button color="primary" type="submit">
                  Edit
                </Button>
              </Col>
            </FormGroup>
          </Form>
        </>
      )}
    </>
  );
};
//TODO: Use i18n
export default withAuthenticationRequired(EditMetric, {
  onRedirecting: () => <Loading />,
});
