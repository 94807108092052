import React from 'react';
import '../../assets/css/profile/metrics.css';

const PrizeBadge = ({ active, alt, badge }) => {
  const classes = `metrics-badge-box ${active ? 'metrics-badge-active' : ''}`;
  return (
    <div className={classes}>
      <img alt={alt} src={badge} />
    </div>
  );
};

export default PrizeBadge;
