import React from 'react';

import '../../assets/css/profile/profile-stats.css';

const StatsItem = ({ img, text, value }) => {
  return (
    <div className="stats-profile-item">
      <div>
        <img src={img} alt={text} />
      </div>
      <div className="stats-profile-item-info">
        <span className="stats-profile-item-info-value">{value}</span>
        <span className="stats-profile-item-info-title">{text}</span>
      </div>
    </div>
  );
};

export default StatsItem;
