import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, FormGroup, Alert, Col, Card, CardBody, CardHeader, Row } from 'reactstrap';

import '../../i18n/i18n.js';
import { useTranslation } from 'react-i18next';
import useExperienceForm from '../../hooks/useExperienceForm.js';
import ReturnButton from '../../components/ReturnButton.js';
import Loading from '../../components/Loading.js';

const ExperienceDetailForm = () => {
  const { id } = useParams();
  const { experience, error, loading } = useExperienceForm(id);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  return (
    <>
      {error && <Alert color="danger">{error}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{id ? t('content.edit') : t('content.add')}</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col>
                <FormGroup className="my-2">
                  <ReturnButton to="/experiences" />
                  <Button color="primary" disabled={loading}>
                    {t('buttons.ok')}
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default withAuthenticationRequired(ExperienceDetailForm, {
  onRedirecting: () => <Loading />,
});
