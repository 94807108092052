import React, { Fragment, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useAppAuthenticated } from '../storage/storage';

const NoMembership = () => {
  const navigate = useNavigate();
  const isAppAuthenticated = useAppAuthenticated();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  if (isAppAuthenticated) return <Navigate to="/landing" />;

  return (
    <Fragment>
      <div className="text-center Init my-5">
        <p className="lead">{t('membership.no')}</p>
        <Button onClick={() => navigate('/logout')} color="primary">
          {t('buttons.back')}
        </Button>
      </div>
    </Fragment>
  );
};

export default NoMembership;
