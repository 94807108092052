import { useEffect, useState } from 'react';
import { useFetchExperienceById } from '../api/ExperiencesAPI';

const useExperienceForm = (id) => {
  const { getExperienceById } = useFetchExperienceById();
  const [experience, setExperience] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchExperience = async () => {
      if (!id) {
        setError('Missing Experience Id');
        return;
      }
      setError(null);
      setLoading(true);
      try {
        setExperience(await getExperienceById(id));
      } catch (err) {
        setError(err.message || 'Failed to fetch experience data.');
      } finally {
        setLoading(false);
      }
    };

    fetchExperience();
  }, [id, getExperienceById]);

  return { experience, error, loading };
};

export default useExperienceForm;
