import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Nav, NavItem, Table } from 'reactstrap';
import Loading from '../../components/Loading';
import AVAILABLE_STATS from './AVAILABLE_STATS';
import ReturnButton from '../../components/ReturnButton';

const ExperienceStats = () => {
  const { id: experienceId } = useParams();
  const navigate = useNavigate();

  let groups = AVAILABLE_STATS.map((s) => s.group);
  groups = groups.filter((item, index) => groups.indexOf(item) === index);

  return (
    <>
      <ReturnButton color="primary" to="/experience" />
      <Table striped>
        <thead>
          <tr>
            <th className="orderable-column text-center" colSpan={2}>
              Estadísticas Disponibles
            </th>
          </tr>
          <tr>
            <th style={{ width: 500 }} className="orderable-column text-center">
              Agrupamiento
            </th>
            <th className="orderable-column text-center">Estadística</th>
          </tr>
        </thead>
        <tbody>
          {groups.map((group) => (
            <tr>
              <td className="text-center">{group}</td>
              <td>
                <Nav vertical>
                  {AVAILABLE_STATS.filter((s) => s.group === group).map((item) => (
                    <NavItem active color="info">
                      <Button color="link" onClick={() => navigate(`/experiences/${experienceId}/stats/${item.name}`)}>
                        {item.label}
                      </Button>
                    </NavItem>
                  ))}
                </Nav>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default withAuthenticationRequired(ExperienceStats, {
  onRedirecting: () => <Loading />,
});
