import axios from 'axios';
import React, { useEffect } from 'react';
import { Navigate, NavLink, useLocation } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { hasRole } from '../utils/roleUtil';
import { applyHeaders } from '../api/axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useActor } from '../storage/storage';
import { useCreateUserTrack } from '../api/UserTrackAPI';

import Loading from '../components/Loading';
import '../i18n/i18n';

const LandingPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const actor = useActor();
  const { t, i18n } = useTranslation();
  const { search } = useLocation();
  const { createUserTrack } = useCreateUserTrack();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    const createLoginTrack = async () => {
      if (!actor) return;
      const messageId = new URLSearchParams(search).get('messageId');
      try {
        const userTrack = {
          companyId: actor.company_id,
          actorId: actor.actor_id,
          event: messageId ? 'LOGIN_WITH_LINK' : 'REGULAR_LOGIN',
        };
        await createUserTrack(userTrack);
      } catch (error) {
        console.error(error);
      }
    };

    void createLoginTrack();
  }, [actor, search, createUserTrack]);

  const makeTrooper = async () => {
    const headers = await applyHeaders(getAccessTokenSilently, 'application/json');
    const url = `${BACKEND_URL}/trooper/${actor.actor_id}`;

    await axios
      .patch(url, {}, headers)
      .then(() => {
        sessionStorage.removeItem('actor');
        window.location.reload();
      })
      .catch((e) => alert(e));
  };

  return (
    <div>
      <h3>{t('chooseOption')}</h3>
      <ListGroup>
        {hasRole(actor, 'TROOPER') && (
          <>
            <ListGroupItem action tag={NavLink} to="/companies">
              {t('menu.companies')}
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/config/daily-chest">
              {t('menu.dailyChest')}
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/config/booster">
              {t('menu.booster')}
            </ListGroupItem>
          </>
        )}
        {hasRole(actor, 'SUPREME_LEADER') && (
          <>
            {actor.full_name.toLowerCase().includes('trooper') && (
              <Button className="btn btn-primary" onClick={makeTrooper}>
                {t('menu.toTrooper')}
              </Button>
            )}
            <ListGroupItem action tag={NavLink} to="/team/boost-requests">
              {t('menu.metricsUpgrade')}
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/jackpot">
              {t('menu.lottery')}
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/prize">
              {t('menu.catalogue')}
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/experiences">
              {t('menu.content')}
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/invite/new-members">
              {t('menu.invLeader')}
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/teams">
              {t('menu.teams')}
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/metrics">
              {t('menu.metrics')}
            </ListGroupItem>
          </>
        )}
        {hasRole(actor, 'PLAYER') && (
          <>
            <Navigate to="/client" />
          </>
        )}
      </ListGroup>
    </div>
  );
};

export default withAuthenticationRequired(LandingPage, {
  onRedirecting: () => <Loading />,
});
