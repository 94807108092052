import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: 'en',
  resources: {
    en: {
      translation: {
        init: 'This is the beginning of something amazing',
        chooseOption: "Please choose the option you'd like to manage",
        metrics: {
          title: 'Metrics',
          value: 'Value',
          tips: 'Tips',
          success: 'Success!',
        },
        membership: {
          no: 'Looks like you have not been invited yet. Ask your team to invite you!',
          new: 'New Team Member',
          channel: 'Notification Channel',
          terms:
            'By checking this box, I agree to get WhatsApp game updates from Galilei Learning. Up to 3 messages daily. Message & data rates may apply. Contact us to opt out. View our terms (galileilearning.com/terms) and privacy policy (galileilearning.com/privacy).',
          noTerms: 'Terms and conditions must be accepted',
        },
        menu: {
          companies: 'Companies',
          toTrooper: 'Back to Trooper view',
          lottery: 'Lottery',
          catalogue: 'Catalogue',
          content: 'Content',
          invLeader: 'Invite people',
          metricsUpgrade: 'Metrics upgrade requests',
          myTeam: 'My Team',
          play: "Let's Play!",
          teams: 'Teams',
          metrics: 'Metrics',
          dailyChest: 'Daily Chest Configuration',
          booster: 'Boosters Configuration',
        },
        form: {
          name: 'Name',
          desc: 'Description',
          img: 'Image',
          logo: 'Logo',
        },
        buttons: {
          login: 'Log in',
          add: 'Add',
          back: 'Return',
          ok: 'Ok',
          redeemedPrizes: 'Redeemed Prizes',
          go: 'Go!',
          reject: 'Reject',
          logout: 'Log out',
          toAdmin: 'Return to manager',
        },
        tables: {
          empty: 'No elements to display',
          name: 'Name',
          sort: 'Sort',
          error: 'Something went wrong while fetching the data',
          available: 'Available',
          redeemed: 'Redeemed',
          select: 'Select',
          quantity: 'Quantity',
          actions: 'Actions',
          active: 'Active',
        },
        config: {
          dailyChest: {
            streak: 'Streak',
            type: 'Chest Type',
            itemQuantity: 'Item Quantity',
            pieceProbability: 'Piece Probability',
            ticketProbability: 'Ticket Probability',
          },
          booster: {
            sequence: 'Sequence',
            type: 'Type',
            small: 'Small',
            medium: 'Medium',
            large: 'Large',
          },
        },
        lottery: {
          add: 'Add Lottery',
          edit: 'Edit Lottery',
          result: {
            title: 'Lottery Winner!',
            body: "We've sent a notification to the lottery winner:",
          },
          run: 'Get Lottery Winner!',
        },
        catalogue: {
          prizes: 'Prizes',
          level: 'Level',
          pieces: '# Pieces',
          add: 'Add Prize',
          edit: 'Edit Prize',
          stock: 'Stock',
        },
        boostRequest: {
          status: 'Status',
          team: 'Team',
          actions: 'Actions',
          change: 'Level Change',
          pending: 'PENDING!',
          approved: 'APPROVED',
          rejected: 'REJECTED',
          expired: 'EXPIRED',
          reason: 'Reason',
          on: 'in',
          expires: 'Expires',
          approvedOn: 'Approved',
          rejectTitle: 'Reject reason',
          yourRequest: 'Your request has been',
          successfully: 'successfully!',
          error: 'Something went wrong while processing the request',
        },
        content: {
          add: 'Add Content',
          edit: 'Edit Content',
          questionsCount: '# Questions',
          stats: 'Stats',
          missing: 'You need to create a lottery and a Catalogue first',
          step1: 'Step 1: Content name',
          step2: 'Step 2: Upload Handbook',
          step3: 'Step 3: Prize Catalogue and Quantities',
          active: 'Enabled',
          inactive: 'Disabled',
          question: 'Question',
          answers: 'Answers',
          correctAns: 'Correct Answer',
          seeQuestions: 'See Questions',
          editDetails: 'Edit Details',
          priority: {
            name: 'Priority',
            levels: {
              low: 'Low',
              medium: 'Medium',
              high: 'High}',
            },
          },
          difficulty: {
            name: 'Difficulty',
            levels: {
              easy: 'Easy',
              medium: 'Medium',
              hard: 'Hard',
            },
          },
        },
        company: {
          supreme: 'View As Supreme',
          leaders: 'Total Leaders',
          players: 'Total Players',
        },
        invitation: {
          sent: 'Invitations sent successfully!',
          send: 'Send',
          clear: 'Clear List',
          phone: 'Phone:',
          list: 'Numbers to invite:',
          lang: 'Language:',
          role: 'Role:',
          team: 'Team:',
          ready: {
            title: 'Invite your Team Members!',
            desc: 'Your team is ready to play along new members',
            slots: 'Available Slots:',
            footer: 'Invite them to earn prizes!',
          },
          full: {
            title: 'There are no more spots available for invitations.',
            body1: 'Your team has filled',
            body2: 'slots. You cannot invite new members.',
            footer: 'But wait...contact us to review your upgrade options.',
          },
        },
        game: {
          home: {
            start: 'Start Mission',
            rewards: 'See Rewards',
            playNow: 'Play now',
            mission: 'Mission',
            timeUp: "Time's up for today",
            openChest: 'Open Chest',
            daily: 'Daily Resources',
            explore: 'Explore Rewards',
          },
          footer: {
            home: 'Home',
            chest: 'Daily Chest',
            rewards: 'Rewards',
            account: 'Account',
          },
          chest: {
            tab: {
              daily: 'Daily Chest',
              content: 'Chest Content',
              next: 'Next chest unlocks in',
            },
            carousel: {
              collect: 'Collect Rewards',
            },
          },
          catalogue: {
            name: {
              bronze: 'Bronze Catalogue',
              silver: 'Silver Catalogue',
              gold: 'Gold Catalogue',
            },
            bronze: 'Bronze',
            silver: 'Silver',
            gold: 'Gold',
            locked: 'Locked',
            unlocked: 'Unlocked',
            pieces: 'GaliPieces',
            tickets: 'GaliTickets',
          },
          lottery: {
            default: {
              name: 'Jackpot coming soon',
              desc: 'Every GaliTicket is a chance to win the mystery jackpot. The mystery jackpot will be revealed soon. Don’t miss out!',
              requirements: ['Play at least 1 month.', 'Store in Silver or Gold.', 'Game accuracy must be over 80%.'],
              drawnByDate: 'Soon...',
            },
            drawnBy: 'Drawn on {{val, datetime}}',
            chances: 'You currently have ({{val}}) chances to WIN!',
          },
          redemption: {
            soldOut: 'Sold Out',
            missing: 'Missing GaliPieces',
            redeemed: 'Already Redeemed',
            redeem: 'Redeem Prize',
            only: 'Only',
            left: 'left in stock',
            repeated: 'repeated pieces',
            redeemedMess: "We've sent your redemption request. You'll be contacted soon to receive your prize.",
            enjoy: 'Enjoy it!',
          },
          end: {
            good: 'Good Job!',
            perform: 'Your performance today',
            return: 'Come back tomorrow to keep winning',
            next: 'Next',
            accomplished: 'Mission Accomplished!',
          },
          stats: {
            name: 'Your stats',
            streak: 'Daily Streak',
            hits: 'hits',
            openChest: 'opened chests',
            timePlayed: 'played time',
            correctAnswers: 'Correct Ans.',
            maxScore: 'Max. Score',
            maxAccuracy: 'Max. Accuracy',
            maxTime: 'Longest game',
            score: 'Score',
            accuracy: 'Accuracy',
            newTopScore: 'New Top Score!',
          },
          profile: {
            leader: 'Leader',
            boost: {
              no: 'No Boost Active!',
              active: 'Active the boost for your team',
              ended: 'Boost ended on',
              sameDayEnded: 'Boost ended today on',
              return: 'Return tomorrow to activated it again',
              until: 'Boost activated till',
              tomorrow: ' tomorrow by',
            },
            prizes: {
              keepPlaying: 'Keep playing and redeem prizes to count them here',
              byPlayer: 'Prizes redeemed by your team',
              byTeam: 'Prizes redeemed by other teams',
            },
            level: {
              name: 'Your team metrics',
            },
            error: 'Oops, something wrong happened...',
          },
          bonus: {
            progress: 'Your Progress',
            earn: 'Earned',
            hits: 'hits!',
            wd: 'Well done!',
          },
        },
        time: {
          sec: 'secs.',
          min: 'min.',
          hours: 'hours',
          days: 'days',
        },
      },
    },
    es: {
      translation: {
        init: 'Este es el inicio de una experiencia sin igual',
        chooseOption: 'Selecciona la opción que deseas gestionar',
        metrics: {
          title: 'Métricas',
          value: 'Valor',
          tips: 'Propinas',
          success: 'Éxito!',
        },
        membership: {
          no: 'Parece que aún no has sido invitado. Pídele a tu equipo que te invite.',
          new: 'Nuevo Miembro de Equipo',
          channel: 'Canal de notificación',
          terms:
            'Al marcar esta casilla, acepto recibir actualizaciones del juego por WhatsApp de Galilei Learning. Hasta 3 mensajes diarios. Pueden aplicarse tarifas de mensajes y datos. Contáctenos para cancelar la suscripción. Consulte nuestros términos (galileilearning.com/terms) y política de privacidad (galileilearning.com/privacy).',
          noTerms: 'Los términos y condiciones deben ser aceptados',
        },
        menu: {
          companies: 'Empresas',
          toTrooper: 'Volver a Trooper',
          lottery: 'Lotería',
          catalogue: 'Catálogo de premios',
          content: 'Contenido',
          invLeader: 'Invitar Líderes',
          metricsUpgrade: 'Solicitudes de mejora de métricas',
          myTeam: 'Mi Equipo',
          play: '¡Juguemos!',
          teams: 'Equipos',
          metrics: 'Métricas',
        },
        form: {
          name: 'Nombre',
          desc: 'Descripción',
          img: 'Imagen',
        },
        buttons: {
          login: 'Ingresar',
          add: 'Agregar nuevo',
          back: 'Regresar',
          ok: 'Aceptar',
          redeemedPrizes: 'Premios Redimidos',
          go: '¡Vamos!',
          reject: 'Rechazar',
          logout: 'Cerrar sesión',
          toAdmin: 'Regresar a Admón',
        },
        tables: {
          empty: 'No se encontraron registros',
          name: 'Nombre',
          sort: 'Ordenar',
          error: 'Un error ocurrió al consultar los registros',
          available: 'Disponibles',
          redeemed: 'Redimidos',
          select: 'Seleccionado',
          quantity: 'Cantidad',
          actions: 'Acciones',
          active: 'Activo',
        },
        lottery: {
          add: 'Nueva Lotería',
          edit: 'Editar Lotería',
          result: {
            title: '¡Hay un ganador!',
            body: 'Hemos enviado una notificación al ganador de la lotería:',
          },
          run: '¡Lanzar Rifa!',
        },
        catalogue: {
          prizes: 'Premios',
          level: 'Nivel',
          pieces: '# de Piezas',
          add: 'Nuevo Premio',
          edit: 'Editar Premio',
          stock: 'Existencias',
        },
        boostRequest: {
          status: 'Estado',
          team: 'Equipo',
          actions: 'Acciones',
          change: 'Cambio de Nivel',
          pending: 'PENDIENTE!',
          approved: 'APROBADA',
          rejected: 'RECHAZADA',
          expired: 'EXPIRADA',
          reason: 'Razón',
          on: 'en',
          expires: 'Expira',
          approvedOn: 'Aprobada',
          rejectTitle: 'Razón de Rechazo',
          yourRequest: '¡Tu petición ha sido',
          successfully: 'correctamente!',
          error: 'Hubo un error al intentar guardar los cambios',
        },
        content: {
          add: 'Agregar Contenido',
          edit: 'Editar Contenido',
          questionsCount: '# de Preguntas',
          stats: 'Estadísticas de juego',
          missing: 'No has creado una lotería o el catálogo de premios.',
          step1: 'Paso 1: Nombre del Contenido',
          step2: 'Paso 2: Manual Operativo',
          step3: 'Paso 3: Asociar Catálogo de Premios y cantidades',
          active: 'Activa',
          inactive: 'Inactiva',
          question: 'Pregunta',
          answers: 'Respuestas',
          correctAns: 'Respuesta Correcta',
          priority: {
            name: 'Prioridad',
            levels: {
              low: 'Baja',
              medium: 'Media',
              high: 'Alta',
            },
          },
          difficulty: {
            name: 'Dificultad',
            levels: {
              easy: 'Fácil',
              medium: 'Media',
              hard: 'Difícil',
            },
          },
        },
        company: {
          supreme: 'Ver como Líder Supremo',
          leaders: 'Total de Líderes',
          players: 'Total de Jugadores',
        },
        invitation: {
          sent: '¡Invitaciones enviadas satisfactoriamente!',
          send: 'Enviar invitaciones',
          clear: 'Limpiar lista',
          phone: 'Número Móvil:',
          list: 'Lista de números móviles:',
          lang: 'Idioma:',
          role: 'Rol:',
          team: 'Equipo:',
          ready: {
            title: '¡Invita a tus compañeros de equipo!',
            desc: 'Tu equipo está listo para jugar junto con nuevos compañeros',
            slots: 'Plazas disponibles:',
            footer: '¡Invítalos para reclamar premios!',
          },
          full: {
            title: 'No hay más plazas disponibles para invitar.',
            body1: 'Tu equipo ya ocupo sus',
            body2: 'plazas de invitación. Ya no puedes invitar a más miembros a tu equipo.',
            footer: 'Contacta a ventas para revisar tus opciones de invitación.',
          },
        },
        game: {
          home: {
            start: 'Iniciar Misión',
            rewards: 'Ver Recompensas',
            playNow: 'Juega Ya',
            mission: 'Misión',
            timeUp: 'Eso es todo por hoy',
            openChest: 'Abrir Cofre',
            daily: 'Recursos Diarios',
            explore: 'Explorar Recompensas',
          },
          footer: {
            home: 'Inicio',
            chest: 'Cofre Diario',
            rewards: 'Recompensas',
            account: 'Cuenta',
          },
          chest: {
            tab: {
              daily: 'Cofre Diario',
              content: 'Contenido de Cofre',
              next: 'Próximo cofre',
            },
            carousel: {
              collect: 'Obtener Recompensas',
            },
          },
          catalogue: {
            name: {
              bronze: 'Catálogo Bronce',
              silver: 'Catálogo Plata',
              gold: 'Catálogo Oro',
            },
            bronze: 'Bronce',
            silver: 'Plata',
            gold: 'Oro',
            locked: 'Bloqueado',
            unlocked: 'Desbloqueado',
            pieces: 'GaliPiezas',
            tickets: 'GaliTiquetes',
          },
          lottery: {
            default: {
              name: 'Próximamente revelación del premio mayor',
              desc: 'Prepárate para algo increíble. El premio misterioso se revelará pronto. No te lo pierdas.',
              requirements: [
                'Jugar al menos 1 mes.',
                'Tienda en Categoría Silver o Oro.',
                'La precisión del juego debe ser superior al 80%.',
              ],
              drawnByDate: 'Pronto...',
            },
            drawnBy: 'Juega el {{val, datetime}}',
            chances: 'Actualmente tienes ({{val}}) oportunidades de GANAR!',
          },
          redemption: {
            soldOut: 'Agotado',
            missing: 'GaliPieces Faltantes',
            redeemed: 'Premio Reclamado',
            redeem: 'Reclamar Premio',
            only: 'Sólo',
            left: 'restantes en inventario',
            repeated: 'piezas repetidas',
            redeemedMess: 'Ya enviamos tu solicitud de redención. Pronto te contactarán para entregarte tu premio.',
            enjoy: '¡Que lo disfrutes!',
          },
          end: {
            good: '¡Buen trabajo!',
            perform: 'Tu desempeño de hoy',
            return: 'Vuelve mañana para seguir ganando.',
            next: 'Siguiente',
            accomplished: 'Misión Completada!',
          },
          stats: {
            name: 'Tus estadísticas',
            streak: 'días en racha',
            hits: '% de aciertos',
            openChest: 'cofres abiertos',
            timePlayed: 'tiempo jugado',
            correctAnswers: 'Resp. correctas',
            maxScore: 'Máx. Puntaje',
            maxAccuracy: 'Máx. Precisión',
            maxTime: 'Juego más largo',
            score: 'Puntaje',
            accuracy: 'Precisión',
            newTopScore: 'Nuevo Puntaje Alto!',
          },
          profile: {
            leader: 'Líder',
            boost: {
              no: '¡No has sido boosteado!',
              active: 'Activa el potenciador grupal',
              ended: 'Potenciamiento acabó el',
              sameDayEnded: 'Potenciamiento acabó hoy a las',
              return: 'Vuelve mañana para activarlo nuevamente',
              until: 'Potenciamiento está activo hasta las',
              tomorrow: ' mañana a las',
            },
            prizes: {
              keepPlaying: 'Sigue jugando y redime premios para verlos aquí',
              byPlayer: 'Premios de tu equipo',
              byTeam: 'Premios de equipos',
            },
            level: {
              name: 'Métricas de tu equipo',
            },
            error: 'Oops, ocurrió un error...',
          },
          bonus: {
            progress: 'Tu Progreso',
            earn: 'Ganadas',
            hits: 'aciertos!',
            wd: '¡Bien hecho!',
          },
        },
        time: {
          sec: 'seg.',
          min: 'min.',
          hours: 'horas',
          days: 'días',
        },
      },
    },
    fr: {
      translation: {
        init: "Ceci est le début de quelque chose d'extraordinaire",
        chooseOption: "Veuillez choisir l'option que vous souhaitez gérer",
        membership: {
          no: "Il semble que vous n'ayez pas encore été invité. Demandez à votre équipe de vous inviter !",
          new: "Nouveau membre de l'équipe",
        },
        menu: {
          companies: 'Entreprises',
          toTrooper: 'Retour à la vue Trooper',
          lottery: 'Loterie',
          catalogue: 'Catalogue',
          content: 'Contenu',
          invLeader: 'Inviter des personnes',
          metricsUpgrade: 'Demandes de mise à niveau des métriques',
          myTeam: 'Mon équipe',
          play: 'Jouons !',
          metrics: 'Métriques',
        },
        form: {
          name: 'Nom',
          desc: 'Description',
          img: 'Image',
          logo: 'Logo',
        },
        buttons: {
          login: 'Se connecter',
          add: 'Ajouter',
          back: 'Retour',
          ok: "D'accord",
          redeemedPrizes: 'Prix échangés',
          go: 'Allons-y !',
          reject: 'Rejeter',
          logout: 'Déconnexion',
          toAdmin: 'Retour au manager',
        },
        tables: {
          empty: 'Aucun élément à afficher',
          name: 'Nom',
          sort: 'Trier',
          error: 'Une erreur est survenue lors de la récupération des données',
          available: 'Disponible',
          redeemed: 'Échangé',
          select: 'Sélectionner',
          quantity: 'Quantité',
          actions: 'Actions',
          active: 'Actif',
        },
        lottery: {
          add: 'Ajouter une loterie',
          edit: 'Modifier la loterie',
          result: {
            title: 'Gagnant de la loterie !',
            body: 'Nous avons envoyé une notification au gagnant de la loterie:',
          },
          run: 'Désigner le gagnant de la loterie !',
        },
        catalogue: {
          prizes: 'Prix',
          level: 'Niveau',
          pieces: 'Nombre de pièces',
          add: 'Ajouter un prix',
          edit: 'Modifier le prix',
          stock: 'Stock',
        },
        boostRequest: {
          status: 'Statut',
          team: 'Équipe',
          actions: 'Actes',
          change: 'Changement de niveau',
          pending: 'EN ATTENTE !',
          approved: 'APPROUVÉ',
          rejected: 'REJETÉ',
          expired: 'EXPIRÉ',
          reason: 'Raison',
          on: 'dans',
          expires: 'Expire',
          approvedOn: 'Approuvé',
          rejectTitle: 'Raison du rejet',
          yourRequest: 'Votre demande a été',
          successfully: 'avec succès !',
          error: "Une erreur s'est produite lors du traitement de la demande",
        },
        content: {
          questionsCount: 'Nombre de questions',
          stats: 'Statistiques',
          missing: "Vous devez créer une loterie et un catalogue d'abord",
          step1: 'Étape 1: Nom du contenu',
          step2: 'Étape 2: Télécharger le manuel',
          step3: 'Étape 3: Catalogue de prix et quantités',
          active: 'Activé',
          inactive: 'Désactivé',
          question: 'Question',
          answers: 'Réponses',
          correctAns: 'Bonne réponse',
          priority: {
            name: 'priorité',
            levels: {
              low: 'faible ',
              medium: 'moyenne',
              high: 'haute',
            },
          },
          difficulty: {
            name: 'Difficulté',
            levels: {
              easy: 'Facile',
              medium: 'Moyenne',
              hard: 'Difficile',
            },
          },
        },
        company: {
          supreme: 'Voir en tant que suprême',
          leaders: 'Nombre total de leaders',
          players: 'Nombre total de joueurs',
        },
        invitation: {
          sent: 'Invitations envoyées avec succès !',
          send: 'Envoyer les invitations',
          clear: 'Effacer la liste',
          phone: 'Téléphone:',
          list: 'Nombres à inviter:',
          lang: 'Langue:',
          role: 'Rôle:',
          team: 'Équipe:',
          ready: {
            title: 'Invite tes coéquipiers !',
            desc: 'Ton équipe est prête à jouer avec de nouveaux coéquipiers.',
            slots: 'Emplacements disponibles:',
            footer: 'Vous pouvez maintenant inviter votre équipe !',
          },
          full: {
            title: "Il n'y a plus de places disponibles pour les invitations.",
            body1: 'Votre équipe a rempli',
            body2: 'emplacements. Vous ne pouvez pas inviter de nouveaux membres.',
            footer: 'Contactez-nous pour examiner vos options de mise à niveau.',
          },
        },
        game: {
          home: {
            start: 'Commencer la mission',
            rewards: 'Voir les récompenses',
            playNow: 'Jouer maintenant',
            mission: 'Mission 1',
            timeUp: "Le temps est écoulé pour aujourd'hui",
            openChest: 'Ouvrir le coffre',
            daily: 'Ressources quotidiennes',
            explore: 'Explorer les récompenses',
          },
          footer: {
            home: 'Accueil',
            chest: 'Coffre quotidien',
            rewards: 'Récompenses',
            account: 'Compte',
          },
          chest: {
            tab: {
              daily: 'Coffre quotidien',
              content: 'Contenu du coffre',
              next: 'Prochain coffre dans',
            },
            carousel: {
              collect: 'Collecter les récompenses',
            },
          },
          catalogue: {
            name: {
              bronze: 'Catalogue Bronze',
              silver: 'Catalogue Argent',
              gold: 'Catalogue Or',
            },
            bronze: 'Bronze',
            silver: 'Argent',
            gold: 'Or',
            locked: 'Verrouillé',
            unlocked: 'Déverrouillé',
            pieces: 'GaliPieces',
            tickets: 'GaliTickets',
          },
          lottery: {
            default: {
              name: 'Le jackpot sera bientôt dévoilé',
              desc: "Préparez-vous à quelque chose d'incroyable. Le bateau mystère sera bientôt révélé. Ne le manquez pas.",
              requirements: [
                'Jouer au moins 1 mois',
                'Magasiner dans la catégorie Argent ou Or.',
                'La précision du jeu doit être supérieure à 80 %.',
              ],
              drawnByDate: 'Bientôt...',
            },
            drawnBy: 'Joue le {{val, datetime}}',
            chances: 'Vous avez actuellement ({{val}}) chances de GAGNER !',
          },
          redemption: {
            soldOut: 'Épuisé',
            missing: 'GaliPièces manquantes',
            redeemed: 'Déjà échangé',
            redeem: 'Échanger le prix',
            only: 'Seulement',
            left: 'restant en stock',
            repeated: 'pièces répétées',
            redeemedMess:
              "Nous avons envoyé votre demande d'échange. Vous serez contacté bientôt pour recevoir votre prix.",
            enjoy: 'Profitez-en !',
          },
          end: {
            good: 'Bon travail !',
            perform: "Votre performance aujourd'hui",
            return: 'Revenez demain pour continuer à gagner',
            next: 'Suivant',
            accomplished: 'Mission accomplie!',
          },
          stats: {
            name: 'Vos statistiques',
            streak: 'série quotidienne',
            hits: 'coups',
            openChest: 'coffres ouverts',
            timePlayed: 'temps de jeu',
            correctAnswers: 'bonnes réponses',
            score: 'score',
            accuracy: 'Précision',
            newTopScore: 'nouveau meilleur score!',
          },
          profile: {
            leader: 'Leader',
            boost: {
              no: 'Aucun boost actif !',
              active: 'Activer le boost pour votre équipe',
              ended: 'Boost terminé le',
              sameDayEnded: "Boost terminé aujourd'hui",
              return: "Revenez demain pour l'activer de nouveau",
              until: "Boost activé jusqu'à",
              tomorrow: ' demain',
            },
            prizes: {
              keepPlaying: 'Continuez à jouer et échangez des prix pour les comptabiliser ici',
              byPlayer: 'Prix échangés par votre équipe',
              byTeam: "Prix échangés par d'autres équipes",
            },
            level: {
              name: 'Niveau de catalogue actuel',
            },
            error: "Oups, quelque chose s'est mal passé...",
          },
          bonus: {
            progress: 'Votre progression',
            earn: 'Gagné',
            hits: 'coups!',
            wd: 'Bravo!',
          },
        },
        time: {
          sec: 'secs.',
          min: 'min.',
          hours: 'heures',
          days: 'jours',
        },
      },
    },
  },
});

export default i18n;
