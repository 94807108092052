import { useCallback, useState } from 'react';
import { deleteData, fetchData, patchData, postData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';

const useFetchMetrics = () => {
  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const fetchMetrics = useCallback(
    async (id) => {
      try {
        const data = await fetchData(`${BACKEND_URL}/company/${id}`, getAccessTokenSilently);
        setMetrics(data.metrics);
      } catch (error) {
        const errorMessage = error.response?.data?.message || 'Unexpected error occurred while fetching metrics.';
        console.error(errorMessage);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { metrics, loading, error, fetchMetrics };
};

const useCreateMetric = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const createMetric = useCallback(
    async (id, metric) => {
      try {
        await postData(`${BACKEND_URL}/company/${id}/metrics`, metric, getAccessTokenSilently);
      } catch (error) {
        const errorMessage = error.response?.data?.message || 'Unexpected error occurred while creating metrics.';
        console.error(errorMessage);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { loading, error, createMetric };
};

const useUpdateMetric = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const updateMetric = useCallback(
    async (id, metric) => {
      try {
        await patchData(`${BACKEND_URL}/company/${id}/metrics`, metric, getAccessTokenSilently);
      } catch (error) {
        const errorMessage = error.response?.data?.message || 'Unexpected error occurred while updating metric.';
        console.error(errorMessage);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { loading, error, updateMetric };
};

const useDeleteMetric = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const deleteMetric = useCallback(
    async (id, metricId) => {
      try {
        await deleteData(`${BACKEND_URL}/company/${id}/metrics/${metricId}`, getAccessTokenSilently);
      } catch (error) {
        if (error.response.status !== 400) {
          const errorMessage = error.response?.data?.message || 'Unexpected error occurred while deleting the metric.';
          console.error(errorMessage);
          setError(errorMessage);
        }
        setError('The metric is assigned to a team and cannot be deleted');
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { loading, error, deleteMetric };
};

export { useFetchMetrics, useCreateMetric, useUpdateMetric, useDeleteMetric };
