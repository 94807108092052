import React from 'react';

import galiPiece from '../assets/img/icons/galipiece.svg';
import galiTicket from '../assets/img/icons/galiticket.svg';
import boost from '../assets/img/icons/boost.svg';

import '../assets/css/header.css';
import { useNavigate } from 'react-router-dom';
import { useCompany, useInventory } from '../storage/storage';

const Header = () => {
  const company = useCompany();
  const inventory = useInventory();

  const navigate = useNavigate();

  //thousand, hundred,decimal
  const getDeltaSize = (value) => {
    return value >= 100 ? (value >= 1000 ? 'T' : 'H') : 'D';
  };

  const formatValue = (metric, value) => {
    if (metric !== 'T') return value;
    return `${Math.floor(value / 1000)}K`;
  };

  const prizePieces = inventory.prize_pieces;
  const totalTickets = inventory.total_tickets;

  const totalPieces = prizePieces
    .map((prizePiece) => prizePiece.pieces)
    .map((pieceCounter) => pieceCounter.reduce((acc, v) => acc + parseInt(v.counter), 0))
    .reduce((acc, v) => acc + v, 0);

  const deltaSizeTickets = getDeltaSize(parseInt(totalTickets));
  const deltaSizePiece = getDeltaSize(totalPieces);
  const ticketsFormatted = formatValue(deltaSizeTickets, parseInt(totalTickets));
  const piecesFormatted = formatValue(deltaSizePiece, totalPieces);

  return (
    <header>
      <div className="box">
        <img className="logo-company" src={company.url} alt="Company" />
      </div>
      <div className="box" onClick={() => navigate(`/prize-catalog`)}>
        <div>
          <img src={boost} alt="profile" />
          <span>0</span>
        </div>
        <div>
          <img src={galiPiece} alt="profile" />
          <span>{piecesFormatted}</span>
        </div>
        <div>
          <img src={galiTicket} alt="profile" />
          <span>{ticketsFormatted}</span>
        </div>
      </div>
    </header>
  );
};

export default Header;
