import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

import { useInventory, useJackpot } from '../../storage/storage';
import { isValidUrl } from '../../utils/componentUtil';
import { DEFAULT_LOTTERY } from '../../utils/constants';
import { colorText } from '../../utils/coloredText';

import GameContainer from '../GameContainer';
import Loading from '../../components/Loading';

import '../../assets/css/buttons.css';
import '../../assets/css/info-prize.css';
import '../../i18n/i18n.js';

import backArrow from '../../assets/img/icons/back.svg';
import galiTicket from '../../assets/img/icons/galiticket.svg';
import lotteryDefault from '../../assets/img/default/lottery-fullscreen.jpg';
import jackpotFlame from '../../assets/img/icons/jackpot-flame.svg';

const JackpotDetail = () => {
  const jackpot = useJackpot();
  const inventory = useInventory();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const buildStyle = (url) => {
    const validUrl = isValidUrl(url) ? url : lotteryDefault;
    return {
      backgroundPosition: 'center top',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${validUrl})`,
    };
  };

  const translatedDescription = jackpot
    ? jackpot.description[i18n.resolvedLanguage] || jackpot.description['en']
    : t(DEFAULT_LOTTERY.description);
  const translatedRequirements = jackpot
    ? jackpot.requirements[i18n.resolvedLanguage] || jackpot.requirements['en']
    : t(DEFAULT_LOTTERY.requirements, { returnObjects: true });

  const formateDate = (date) => {
    return {
      val: new Date(date),
      formatParams: {
        val: { year: undefined, month: 'long', day: 'numeric' },
      },
    };
  };

  return (
    <GameContainer active={'JigSaw'}>
      <div className="info-prize-container">
        <img
          className="info-prize-img-back-btn"
          src={backArrow}
          alt="jigsaw"
          onClick={() => navigate('/prize-catalog')}
        />
        <div className="info-prize-img" style={buildStyle(jackpot?.coverUrl || DEFAULT_LOTTERY.url)}></div>
        <div className="info-prize-info">
          <p className="info-reward-info-title">
            <div>
              <span>{jackpot?.name || t(DEFAULT_LOTTERY.name)}</span>
              <span style={{ float: 'right' }}>
                <img src={galiTicket} alt="GaliTickets" style={{ width: '20px' }} />
                &nbsp;{inventory.total_tickets}
              </span>
            </div>
            <img src={jackpotFlame} alt={'Win this day!'} style={{ width: '20px', height: '13px' }} />
            <font style={{ color: '#FFA9A9', fontWeight: 'lighter', fontSize: '16px' }}>
              {jackpot
                ? t('game.lottery.drawnBy', formateDate(jackpot.drawn_by))
                : t('game.lottery.default.drawnByDate')}
            </font>
            <br />
            <span>
              <font style={{ fontWeight: 'lighter', fontSize: '16px' }}>
                {t('game.lottery.chances', { val: inventory.total_tickets })}
              </font>
            </span>
          </p>
          <p className="info-reward-info-detail">{colorText(translatedDescription)}</p>
          {translatedRequirements?.length > 0 && (
            <p className="info-reward-info-requirement">
              <bold>Requirements:</bold>
              <ul>
                {translatedRequirements.map((item) => (
                  <li style={{ fontWeight: 'lighter' }}>{colorText(item)}</li>
                ))}
              </ul>
            </p>
          )}
        </div>
      </div>
    </GameContainer>
  );
};

export default withAuthenticationRequired(JackpotDetail, {
  onRedirecting: () => <Loading />,
});
