import Loading from '../components/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Table, Button, FormGroup, Alert, Card, CardBody, CardHeader, ButtonGroup } from 'reactstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import ReturnButton from '../components/ReturnButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useFetchJackpotList } from '../api/JackpotAPI';
import LotteryModal from './lottery/LotteryModal';

const JackpotList = () => {
  const { data: jackpots, isLoading, isError } = useFetchJackpotList();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  if (isLoading) return <Loading />;

  return (
    <div>
      {isError && <Alert color="danger">{isError}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{t('menu.lottery')}</h3>
        </CardHeader>
        <CardBody>
          <ButtonGroup>
            <ReturnButton to={'/landing'} />
            <NavLink className="btn btn-primary" to="new">
              {t('buttons.add')}
            </NavLink>
          </ButtonGroup>
          <LotteryModal />
          <FormGroup row>{!jackpots && <Alert color="light">{t('tables.empty')}</Alert>}</FormGroup>
          {jackpots && (
            <Table hover responsive size="sm" striped className="text-center">
              <thead>
                <tr key="header">
                  <th>{t('tables.name')}</th>
                  <th>{t('tables.active')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {jackpots.map((jackpot) => (
                  <tr key={jackpot.jackpot_id}>
                    <td>
                      <span className="text-dark badge">{jackpot.name}</span>
                    </td>
                    <td>
                      <span className="text-dark badge">{jackpot.active ? '🟢' : '🔴'}</span>
                    </td>
                    <td>
                      <Button
                        color="link"
                        size="sm"
                        className="view-icon"
                        onClick={() => navigate(`${jackpot.jackpot_id}/edit`)}
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default withAuthenticationRequired(JackpotList, {
  onRedirecting: () => <Loading />,
});
