import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';

import { hasRole } from '../utils/roleUtil';
import { fetchData } from '../api/axiosClient';
import { getPrizesWithDetails } from '../api/PrizesAPI';
import { BACKEND_URL } from '../utils/constants';

import { setPrizes, useActor, useInventory } from '../storage/storage.js';
import { useStorageFetchActiveJackpot } from '../api/JackpotAPI.js';

import Home from './Home';
import GameContainer from './GameContainer';
import Loading from '../components/Loading';
import '../assets/css/client.css';
import '../assets/css/buttons.css';

const Client = () => {
  const navigate = useNavigate();
  window.onfocus = () => navigate('/client');

  const actor = useActor();
  const inventory = useInventory();
  const dispatch = useDispatch();
  useStorageFetchActiveJackpot();

  const { game, isPlayed } = useSelector((state) => state.game);
  const { getAccessTokenSilently } = useAuth0();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPrizesListByCompanyId = async () => {
      try {
        const response = await fetchData(`${BACKEND_URL}/prize/${actor.company_id}/all`, getAccessTokenSilently);
        setPrizes(getPrizesWithDetails(inventory, response.data), dispatch);
      } catch (error) {
        console.error('There was an error fetching the prizes list!', error);
      } finally {
        setLoading(false);
      }
    };

    void fetchPrizesListByCompanyId();
  }, [inventory, dispatch, getAccessTokenSilently]);

  if (loading) return <Loading />;
  if (!hasRole(actor, 'PLAYER')) return <Navigate to={'/landing'} />;
  if (game && !isPlayed) return <Navigate to="/questionary" />;

  return (
    <GameContainer active={'Game'}>
      <Home />
    </GameContainer>
  );
};

export default withAuthenticationRequired(Client, {
  onRedirecting: () => <Loading />,
});
