import Loading from '../components/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Table, FormGroup, Alert, Card, CardBody, CardHeader, Input } from 'reactstrap';
import { useParams } from 'react-router-dom';
import ReturnButton from '../components/ReturnButton';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useFetchPlayersByTeam, useFetchTeam } from '../api/TeamAPI';

const PlayerList = () => {
  const { players, loading: playersLoading, error: playersError, fetchPlayersByTeam } = useFetchPlayersByTeam();
  const { team, loading: teamLoading, error: teamError, fetchTeam } = useFetchTeam();
  const { id } = useParams();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    if (!id) return;
    const fetchPlayers = async () => {
      try {
        await fetchTeam(id);
        await fetchPlayersByTeam(id);
      } catch (error) {
        console.error('Failed to fetch team or players data:', error.message);
      }
    };

    fetchPlayers();
  }, [id, fetchTeam, fetchPlayersByTeam]);

  if (teamLoading || playersLoading) return <Loading />;
  const combinedError = teamError || playersError;

  return (
    <div>
      <ReturnButton to={'/teams'} />
      {combinedError && <Alert color="danger">{combinedError}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>{team && <h3>{team.name}</h3>}</CardHeader>
        <CardBody>
          <FormGroup row>{!players && <Alert color="light">{t('tables.empty')}</Alert>}</FormGroup>
          {players && (
            <Table hover responsive size="sm" striped className="text-center">
              <thead>
                <tr key="header">
                  <th>{t('tables.name')}</th>
                  <th>{t('tables.active')}</th>
                </tr>
              </thead>
              <tbody>
                {players.map((player) => (
                  <tr key={player.player_id}>
                    <td>{player.full_name}</td>
                    <td style={{ display: 'flex', justifyContent: 'center' }}>
                      <FormGroup switch>
                        <Input type="switch" checked={player.active} onChange={() => player.active} />
                      </FormGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default withAuthenticationRequired(PlayerList, {
  onRedirecting: () => <Loading />,
});
