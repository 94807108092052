import { Carousel, CarouselControl, CarouselIndicators, CarouselItem } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useFetchExperienceListForPlayer } from '../api/ExperiencesAPI';
import { useTranslation } from 'react-i18next';

const ExperienceCarousel = ({ setExperienceId }) => {
  const { t, i18n } = useTranslation();
  const { experienceList } = useFetchExperienceListForPlayer(i18n.resolvedLanguage);
  const galiExperiences = experienceList || [];

  useEffect(() => {
    void i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    if (galiExperiences.length > 0) setExperienceId(galiExperiences[0].experience_id);
  }, [galiExperiences]);

  const [activeIndex, setActiveIndex] = useState(0);

  const next = () => {
    const nextIndex = activeIndex === galiExperiences.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
    setExperienceId(galiExperiences[nextIndex].experience_id);
  };

  const previous = () => {
    const nextIndex = activeIndex === 0 ? galiExperiences.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
    setExperienceId(galiExperiences[nextIndex].experience_id);
  };

  const goToIndex = (newIndex) => {
    setActiveIndex(newIndex);
    setExperienceId(galiExperiences[newIndex].experience_id);
  };

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous} ride={null} interval={false}>
      <CarouselIndicators items={galiExperiences} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {galiExperiences.map((item, index) => (
        <CarouselItem key={item.experience_id}>
          <div className="experience-title">
            <h1>
              {t('game.home.mission')} {index + 1}
            </h1>
            <p>{item.name}</p>
            <img
              src={item.image}
              alt={item.altText}
              onClick={() => setExperienceId(item.experience_id)}
              style={{ borderRadius: '50%', width: '75%', aspectRatio: '1/1' }}
            />
          </div>
        </CarouselItem>
      ))}
      {galiExperiences.length > 1 && (
        <>
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </>
      )}
    </Carousel>
  );
};

export default ExperienceCarousel;
