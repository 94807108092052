import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getCatalogueLevelLabel,
  PRIZE_REDEMPTION_CLASS_NAMES,
  PRIZE_REDEMPTION_TEXT_KEYS,
} from '../../../utils/constants';

export const usePrizeRedemptionButton = (info, level) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const userLanguage = navigator.language;
    if (i18n.language !== userLanguage) {
      i18n.changeLanguage(userLanguage);
    }
  }, [i18n]);

  const buttonClass = useMemo(() => {
    if (info.locked) return `${PRIZE_REDEMPTION_CLASS_NAMES.base} ${PRIZE_REDEMPTION_CLASS_NAMES.locked}`;
    if (info.canRedeem) return `${PRIZE_REDEMPTION_CLASS_NAMES.base} ${PRIZE_REDEMPTION_CLASS_NAMES.primary}`;
    return `${PRIZE_REDEMPTION_CLASS_NAMES.base} ${PRIZE_REDEMPTION_CLASS_NAMES.unavailable}`;
  }, [info]);

  const innerText = useMemo(() => {
    if (info.redeemed) return t(PRIZE_REDEMPTION_TEXT_KEYS.redeemed);
    if (info.canRedeem) return t(PRIZE_REDEMPTION_TEXT_KEYS.redeem);
    if (info.locked) return `${t(getCatalogueLevelLabel(level))} ${t(PRIZE_REDEMPTION_TEXT_KEYS.locked)}`;
    if (info.missingPieces) return t(PRIZE_REDEMPTION_TEXT_KEYS.missingPieces);
    if (info.soldOut) return t(PRIZE_REDEMPTION_TEXT_KEYS.soldOut);
    return '';
  }, [info, level, t]);

  const isDisabled = useMemo(() => !info.canRedeem, [info.canRedeem]);

  return { buttonClass, innerText, isDisabled };
};
