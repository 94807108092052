import { useEffect } from 'react';
import { Card, CardBody, CardImg, CardImgOverlay, CardText } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import galiTicket from '../../assets/img/icons/galiticket.svg';
import lotteryDefault from '../../assets/img/default/lottery-catalog.png';
import { DEFAULT_LOTTERY } from '../../utils/constants';
import { useCreateUserTrack } from '../../api/UserTrackAPI';

const JackpotCard = ({ jackpot }) => {
  const { trackAndNavigate } = useCreateUserTrack();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const handleDefaultImage = (e) => {
    e.target.src = lotteryDefault;
  };

  return (
    <Card color="dark" inverse onClick={() => trackAndNavigate('/jackpot-detail', 'JACKPOT_CLICK')}>
      <CardImg
        alt="Jackpot Image"
        src={jackpot?.url || DEFAULT_LOTTERY.url}
        top
        width="100%"
        onError={handleDefaultImage}
      />
      <CardImgOverlay>
        <CardBody>
          <CardText style={{ position: 'absolute', bottom: '0px', left: '10px' }}>
            <img src={galiTicket} alt="GaliTickets" style={{ width: '32px' }} />
            <div>{t('game.catalogue.jackpotMessage')}</div>
          </CardText>
        </CardBody>
      </CardImgOverlay>
    </Card>
  );
};

export default JackpotCard;
