import { useEffect, useState } from 'react';

import FineSpinner from './components/FineSpinner';

const FineModal = ({ onFinish, worker, penaltyReduction }) => {
  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    if (!worker) return;
    worker.postMessage({
      penaltyReduction,
    });
    worker.onmessage = function (event) {
      setCountdown(event.data);
      if (event.data.ended) {
        worker.terminate();
        onFinish();
      }
    };
  }, [setCountdown]);

  return (
    countdown && (
      <div className="incorrect-modal">
        <FineSpinner />
        <div className="fine-counter">
          <span>{countdown.remainingTime}</span>
        </div>
      </div>
    )
  );
};

export default FineModal;
