import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import ReturnButton from '../../components/ReturnButton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../../components/Loading';
import useEditQuestion from '../../hooks/useEditQuestion';
import { QUESTION_DIFFICULTY, QUESTION_PRIORITY_TEXT } from '../../utils/constants';

import '../../i18n/i18n';
import '../../assets/css/edit-question.css';

const EditQuestion = () => {
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState('en');
  const { id, questionId } = useParams();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const {
    question,
    imageFile,
    error,
    loading,
    handleQuestionInput,
    handleAnswerInput,
    handleCorrectAnswerInput,
    handlePriorityChange,
    handleDifficultyChange,
    handleAddQuestionOptions,
    handleRemoveQuestionOptions,
    handleImage,
    handleSubmit,
  } = useEditQuestion(questionId, id);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  if (loading) return <Loading />;

  return (
    <>
      {error && <Alert color="danger">{error}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{t('content.question')}</h3>
        </CardHeader>
        {question && (
          <CardBody>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Card className="image-card-container">
                    <CardHeader id="prizeCard">
                      <FormGroup>
                        <Label className="admin-text" for="image">
                          {t('form.img')}
                        </Label>
                        <Input type="file" name="image" accept="image/*" id="image" onChange={handleImage} />
                      </FormGroup>
                    </CardHeader>
                    <CardBody>
                      <CardImg
                        className="experience"
                        src={imageFile ? URL.createObjectURL(imageFile) : question.imageUrl ? question.imageUrl : ''}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="priority"
                      name="priority"
                      type="select"
                      value={QUESTION_PRIORITY_TEXT[question.priority]}
                      onChange={handlePriorityChange}
                    >
                      <option />
                      {QUESTION_PRIORITY_TEXT.map((o) => (
                        <option key={o} value={o}>
                          {t(o)}
                        </option>
                      ))}
                    </Input>
                    <Label className="admin-text" for="priority">
                      {t('content.priority.name')}
                    </Label>
                  </FormGroup>
                  <FormGroup floating>
                    <Input
                      id="difficulty"
                      name="difficulty"
                      type="select"
                      value={QUESTION_DIFFICULTY[question.difficulty]}
                      onChange={handleDifficultyChange}
                    >
                      <option />
                      {QUESTION_DIFFICULTY.map((o) => (
                        <option key={o} value={o}>
                          {t(o)}
                        </option>
                      ))}
                    </Input>
                    <Label className="admin-text" for="priority">
                      {t('content.difficulty.name')}
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <Nav tabs>
                    <NavItem>
                      <NavLink href="#" onClick={() => toggle('en')} active={activeTab === 'en'}>
                        English
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="#" onClick={() => toggle('es')} active={activeTab === 'es'}>
                        Español
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="#" onClick={() => toggle('fr')} active={activeTab === 'fr'}>
                        Française
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={activeTab}>
                      <Col>
                        <FormGroup>
                          <Input
                            type="textarea"
                            value={question.text ? question.text[activeTab] : ''}
                            onChange={(event) => handleQuestionInput(event, activeTab)}
                            className="text-area-input"
                          />
                        </FormGroup>
                      </Col>
                      <Row>
                        <Button color="primary" disabled={loading} onClick={handleAddQuestionOptions}>
                          Add option
                        </Button>
                        <Col>
                          <FormGroup>
                            {question.options.map((option) => (
                              <>
                                <Input
                                  value={option.text[activeTab]}
                                  onChange={(event) => handleAnswerInput(event, option.id, activeTab)}
                                />
                                <Button
                                  color="warning"
                                  disabled={loading}
                                  onClick={() => handleRemoveQuestionOptions(option.id)}
                                >
                                  -
                                </Button>
                                <br />
                              </>
                            ))}
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            {question.options.map((option) => (
                              <tr>
                                <td>
                                  <div className="radio-container-answers">
                                    <Label check className="radio-answers-input">
                                      <Input
                                        type="radio"
                                        value={option.id}
                                        checked={option.id === question.validAnswerOptionId}
                                        onChange={(event) => handleCorrectAnswerInput(event)}
                                      />
                                    </Label>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </FormGroup>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="my-2">
                    <ReturnButton to={`/experiences/${id}/questions`} />
                    <Button color="primary" type="submit" disabled={loading}>
                      {t('buttons.ok')}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        )}
      </Card>
    </>
  );
};

export default withAuthenticationRequired(EditQuestion, {
  onRedirecting: () => <Loading />,
});
