import React, { useEffect, useState } from 'react';
import { FormGroup, Alert, Card, CardBody, CardHeader, Input, ButtonGroup, Table, Button } from 'reactstrap';
import Loading from '../../components/Loading';
import { Navigate } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import axios from 'axios';
import { BACKEND_URL } from '../../utils/constants';
import { applyHeaders } from '../../api/axiosClient';
import ReturnButton from '../../components/ReturnButton';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const DailyChestConfig = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [dailyChestConfigList, setDailyChestConfigList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  const [state, setState] = useState({
    redirect: false,
    error: null,
  });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
    const getDailyChestConfig = async () => {
      const headers = await applyHeaders(getAccessTokenSilently);
      await axios
        .get(`${BACKEND_URL}/config/daily-chest`, headers)
        .then((response) => setDailyChestConfigList(response.data.data))
        .catch((e) => setFetchError(e.response.data.message));
    };
    getDailyChestConfig();
  }, [getAccessTokenSilently, setFetchError]);

  const handleInput = (config, index, event, key) => {
    setState({ ...state, error: null });
    const newConfigData = structuredClone(dailyChestConfigList);
    newConfigData[index] = { ...config, [key]: event.target.value };
    setDailyChestConfigList(newConfigData);
  };

  const submit = async (event) => {
    event.preventDefault();
    setState({ ...state, error: null });
    setLoading(true);

    const headers = await applyHeaders(getAccessTokenSilently);
    await axios
      .patch(`${BACKEND_URL}/config/daily-chest`, dailyChestConfigList, headers)
      .then((r) => setState({ ...state, error: null }))
      .catch((e) => {
        console.error(e);
        setState({
          ...state,
          error: 'Something went wrong while updating the daily chest configuration',
        });
      })
      .finally(() => setLoading(false));
  };

  if (state.redirect) return <Navigate to="/landing" />;

  return (
    <div>
      {state.error && <Alert color="danger">{state.error}</Alert>}
      {fetchError && <Alert color="danger">{fetchError}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{t('menu.dailyChest')}</h3>
        </CardHeader>
        <CardBody>
          <ButtonGroup>
            <ReturnButton to={'/landing'} />
            <Button disabled={loading} className="btn-primary" onClick={submit}>
              {t('buttons.ok')}
            </Button>
          </ButtonGroup>
          <FormGroup row>{!dailyChestConfigList && <Alert color="light">{t('tables.empty')}</Alert>}</FormGroup>

          {dailyChestConfigList && (
            <Table hover borderless responsive size="sm" striped className="text-center">
              <thead>
                <tr>
                  <th>{t('config.dailyChest.streak')}</th>
                  <th>{t('config.dailyChest.type')}</th>
                  <th>{t('config.dailyChest.itemQuantity')}</th>
                  <th>{t('config.dailyChest.pieceProbability')}</th>
                  <th>{t('config.dailyChest.ticketProbability')}</th>
                </tr>
              </thead>
              <tbody>
                {dailyChestConfigList.map((config, index) => (
                  <tr key={index}>
                    <td>{config.streak}</td>
                    <td>{config.chest_type}</td>
                    <td>
                      <Input
                        type="text"
                        value={config.item_quantity}
                        onChange={(e) => handleInput(config, index, e, 'item_quantity')}
                      />
                    </td>
                    <td>
                      <Input
                        type="text"
                        value={config.piece_probability}
                        onChange={(e) => handleInput(config, index, e, 'piece_probability')}
                      />
                    </td>
                    <td>
                      <Input
                        type="text"
                        value={config.ticket_probability}
                        onChange={(e) => handleInput(config, index, e, 'ticket_probability')}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default withAuthenticationRequired(DailyChestConfig, {
  onRedirecting: () => <Loading />,
});
