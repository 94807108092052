import { useState, useCallback } from 'react';
import { patchData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { useActor } from '../storage/storage';

const useRunLottery = () => {
  const [winner, setWinner] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const actor = useActor();

  const getWinner = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await patchData(
        `${BACKEND_URL}/company/${actor.company_id}/lottery`,
        {},
        getAccessTokenSilently
      );
      setWinner(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently]);

  return { winner, loading, error, getWinner };
};

export { useRunLottery };
