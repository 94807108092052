import { Navigate, useParams } from 'react-router-dom';
import { useFetchMetrics } from '../../api/CompanyAPI';
import { useActor } from '../../storage/storage';
import { useEffect, useState } from 'react';
import { Form, Button, Col, FormGroup, Input, Label, Alert } from 'reactstrap';
import ReturnButton from '../../components/ReturnButton';
import Loading from '../../components/Loading';
import { useAssignMetric } from '../../api/TeamAPI';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const AssignMetric = () => {
  const { id } = useParams();
  const actor = useActor();
  const { metrics, loading, error: fetchMetricsError, fetchMetrics } = useFetchMetrics();
  const { assignMetric } = useAssignMetric();
  const [formData, setFormData] = useState({ metricId: '' });
  const [formError, setFormError] = useState(null);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (actor.company_id) fetchMetrics(actor.company_id);
  }, [fetchMetrics, actor.company_id]);

  const handleInputChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData.metricId) {
      setFormError('Metric is required');
      return;
    }
    setFormError(null);
    try {
      await assignMetric(id, formData.metricId);
      setRedirect(true);
    } catch (er) {
      setFormError('Error sending asigment:', er);
    }
  };

  const errorMessage = fetchMetricsError || formError;

  if (redirect) return <Navigate to="/teams" />;

  if (loading) return <Loading />;

  return (
    <>
      {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
      <h2 className="admin-text">Team Assigner</h2>
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <Label className="admin-text" for="teamId" sm={1}>
            Metrics
          </Label>
          <Col sm={6}>
            <Input
              type="select"
              value={formData.metricId || ''}
              onChange={(e) => handleInputChange('metricId', e.target.value)}
            >
              <option value="" disabled></option>
              {metrics.length !== 0 &&
                metrics.map((metric) => (
                  <option key={metric.id} value={metric.id}>
                    {metric.name}
                  </option>
                ))}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <ReturnButton to={'/teams'} />
            <Button color="primary" type="submit">
              Asign
            </Button>
          </Col>
        </FormGroup>
      </Form>
    </>
  );
};
//TODO: Use i18n
export default withAuthenticationRequired(AssignMetric, {
  onRedirecting: () => <Loading />,
});
