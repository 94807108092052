import React from 'react';
import '../../assets/css/profile/metrics.css';

const MetricLevel = ({ key, active, text }) => {
  const textClassName = `metric-level-text ${active ? 'metric-level-text-active' : ''}`;
  return (
    <div key={key} className="metric-level">
      <span className={textClassName}>{text}</span>
    </div>
  );
};

export default MetricLevel;
