import { useCallback, useEffect, useState } from 'react';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Form, FormGroup, Label, Col, Row, Input } from 'reactstrap';

import { LANG_OPTIONS, ROLE_OPTIONS } from '../../utils/constants';

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import ReturnButton from '../../components/ReturnButton';
import 'react-phone-number-input/style.css';

import { useActor } from '../../storage/storage';
import { useCreateMembership } from '../../api/MembershipAPI';
import { useFetchTeamsByCompany } from '../../api/TeamAPI';

const InvitationForm = ({ role, formError, refreshMemberships, phoneNumbers, hasCapacity }) => {
  const { error: createMembershipError, createMembership } = useCreateMembership();
  const { teams, fetchTeamsByCompany } = useFetchTeamsByCompany();
  const actor = useActor();
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({ phoneNumber: '', lang: 'en', role: 'PLAYER', teamId: null });

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    if (actor.company_id) fetchTeamsByCompany(actor.company_id);
  }, [fetchTeamsByCompany, actor.company_id]);

  const handleInputChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const error = validateFormData();
    if (error) {
      formError(error);
      return;
    }
    formError(null);
    try {
      await createMembership({
        role,
        sentBy: actor.actor_id,
        invitation: formData,
      });
      resetForm();
      refreshMemberships(actor.company_id);
    } catch (err) {
      console.error('Error sending invitation:', err);
      formError(createMembershipError);
    }
  };

  const validateFormData = () => {
    const { phoneNumber, teamId } = formData;
    if (!phoneNumber || !isValidPhoneNumber(phoneNumber)) return 'Invalid phone number format or is empty';
    if (phoneNumbers.includes(phoneNumber)) return 'This phone number already exists';
    if (!teamId) return 'The team is required.';
    return null;
  };

  const resetForm = useCallback(() => {
    setFormData({ phoneNumber: '', lang: 'en', role: 'PLAYER' });
  }, []);

  return (
    <>
      {hasCapacity && (
        <Form id="inviteForm">
          <Row>
            <Col md={5}>
              <FormGroup>
                <Label className="admin-text" for="phoneNumber">
                  {t('invitation.phone')}
                </Label>
                <PhoneInput
                  id="phoneNumber"
                  placeholder="+13053456789"
                  defaultCountry="US"
                  value={formData.phoneNumber}
                  onChange={(value) => handleInputChange('phoneNumber', value)}
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label className="admin-text" for="roles">
                  {t('invitation.role')}
                </Label>
                <Input type="select" value={formData.role} onChange={(e) => handleInputChange('role', e.target.value)}>
                  {ROLE_OPTIONS.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label className="admin-text" for="teamId">
                  {t('invitation.team')}
                </Label>
                <Input
                  type="select"
                  value={formData.teamId || ''}
                  onChange={(e) => handleInputChange('teamId', e.target.value)}
                >
                  <option value="" disabled></option>
                  {teams.map((team) => (
                    <option key={team.team_id} value={team.team_id}>
                      {team.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup>
                <Label className="admin-text" for="lang">
                  {t('invitation.lang')}
                </Label>
                <Input type="select" value={formData.lang} onChange={(e) => handleInputChange('lang', e.target.value)}>
                  {LANG_OPTIONS.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      )}
      <ButtonGroup>
        <ReturnButton to={'/landing'} />
        {hasCapacity && (
          <Button color="success" block onClick={submitHandler}>
            {t('buttons.add')}
          </Button>
        )}
      </ButtonGroup>
    </>
  );
};

export default InvitationForm;
