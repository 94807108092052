import React from 'react';
import { Container } from 'reactstrap';
import { useParams } from 'react-router-dom';
import AVAILABLE_STATS from '../AVAILABLE_STATS';
import ReturnButton from '../../../components/ReturnButton';

const StatsDetails = () => {
  const { id: experienceId, statsName: statisticsName } = useParams();
  const stats = AVAILABLE_STATS.find((s) => s.name === statisticsName);

  return (
    <Container>
      <ReturnButton color="primary" to={`/experiences/${experienceId}/stats`} />
      <br />
      {stats ? <stats.component experienceId={experienceId} label={stats.label} /> : <p>Statistics not found</p>}
    </Container>
  );
};

export default StatsDetails;
