import { usePrizeRedemptionButton } from '../hooks/usePrizeRedemptionButton';

const PrizeRedemptionButton = ({ info, level, redeem }) => {
  const { buttonClass, innerText, isDisabled } = usePrizeRedemptionButton(info, level);

  return (
    <button className={buttonClass} disabled={isDisabled} onClick={redeem}>
      {innerText}
    </button>
  );
};

export default PrizeRedemptionButton;
