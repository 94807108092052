import React, { useEffect } from 'react';
import { LEVEL_OPTIONS } from '../../utils/constants';

import gold from '../../assets/img/profile/gold.svg';
import silver from '../../assets/img/profile/silver.svg';
import bronze from '../../assets/img/profile/bronze.svg';
import '../../assets/css/profile/metrics.css';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import PrizeBadge from './PrizeBadge';

const PrizeBadgesRibbon = ({ level }) => {
  const levelImages = [bronze, silver, gold];
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  return (
    <div className="metrics-badges-container">
      {LEVEL_OPTIONS.map((element, idx) => (
        <PrizeBadge active={level === idx} alt={t(element.label)} badge={levelImages[idx]} />
      ))}
    </div>
  );
};

export default PrizeBadgesRibbon;
