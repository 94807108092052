import { useState, useEffect, useCallback } from 'react';
import { fetchData, postData, patchData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { usePlayer, useActor } from '../storage/storage';

const useFetchExperienceList = (lang) => {
  const actor = useActor();
  const { getAccessTokenSilently } = useAuth0();
  const [experienceList, setExperienceList] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!actor) return;

    const fetchExperienceList = async () => {
      try {
        const { data: experienceList } = await fetchData(
          `${BACKEND_URL}/company/${actor.company_id}/experiences?lang=${lang}`,
          getAccessTokenSilently
        );
        setExperienceList(experienceList);
      } catch (error) {
        if (error.response.status === 404) return;
        console.error('There was an error fetching the experiences list!', error);
        setError(error);
      }
    };

    fetchExperienceList();
  }, [actor, lang, setExperienceList, getAccessTokenSilently]);

  return { experienceList, error };
};

const useFetchExperienceListForPlayer = (lang) => {
  const actor = useActor();
  const player = usePlayer();
  const { getAccessTokenSilently } = useAuth0();
  const [experienceList, setExperienceList] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!actor) return;

    const fetchExperienceList = async () => {
      try {
        const { data: experienceList } = await fetchData(
          `${BACKEND_URL}/company/${actor.company_id}/player/${player.player_id}/experiences?lang=${lang}`,
          getAccessTokenSilently
        );
        setExperienceList(experienceList);
      } catch (error) {
        if (error.response.status === 404) return;
        console.error('There was an error fetching the experiences list!', error);
        setError(error);
      }
    };

    fetchExperienceList();
  }, [actor, lang, setExperienceList, getAccessTokenSilently]);

  return { experienceList, error };
};

const useFetchExperienceById = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const getExperienceById = useCallback(
    async (experienceId) => {
      const url = `${BACKEND_URL}/experience/${experienceId}?raw=true`;
      setLoading(true);
      setError(null);
      try {
        return await fetchData(url, getAccessTokenSilently);
      } catch (error) {
        console.error('There was an error getting the experience by Id!', error);
        const errorMessage = error.response?.data?.message || 'There was an error getting the prize by Id!';
        setError(errorMessage);
        throw new Error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { loading, error, getExperienceById };
};

const usePostExperience = () => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const url = `${BACKEND_URL}/experience`;

  const postExperience = async (data) => {
    setLoading(true);
    setError(null);

    try {
      const result = await postData(url, data, getAccessTokenSilently, 'multipart/form-data');
      setResponse(result);
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { response, loading, error, postExperience };
};

const useUpdateQuestion = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const updateQuestion = async (experienceId, questionData) => {
    const fechaActual = new Date();
    try {
      await patchData(
        `${BACKEND_URL}/experience/${experienceId}/question`,
        questionData,
        getAccessTokenSilently,
        'multipart/form-data'
      );
    } catch (error) {
      console.error('There was an error updating the questions!', error, 'fecha actual: ', fechaActual);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, updateQuestion };
};

export {
  useFetchExperienceList,
  useFetchExperienceListForPlayer,
  useFetchExperienceById,
  usePostExperience,
  useUpdateQuestion,
};
