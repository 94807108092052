import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUpdateRedeemPrize } from '../../../api/InventoryAPI';
import { isValidUrl } from '../../../utils/componentUtil';
import { useInventory, usePrizes, useTeam } from '../../../storage/storage';
import { DEFAULT_FULL_SCREEN_IMG } from '../../../utils/constants';

export const usePrizeRedemption = () => {
  const { id } = useParams();
  const inventory = useInventory();
  const team = useTeam();
  const prizes = usePrizes();
  const { redeemPrize } = useUpdateRedeemPrize();

  const prize = prizes.find((p) => p.prize_id === id);
  const redeemed = !!inventory.prizes[prize.prize_id];
  const [info, setInfo] = useState({
    soldOut: prize.redeemed === prize.stock,
    pricesLeft: prize.stock - prize.redeemed,
    redeemed: redeemed,
    missingPieces: prize.uniquePieces !== prize.total_pieces,
    canRedeem: !redeemed && prize.uniquePieces === prize.total_pieces && team.prize_level >= prize.level,
    locked: team.prize_level < prize.level,
  });

  const redeem = async () => {
    await redeemPrize(inventory.inventory_id, { prizeId: prize.prize_id });

    setInfo((prev) => ({
      ...prev,
      redeemed: true,
      canRedeem: false,
    }));
  };

  const buildStyle = (url, level) => {
    const validUrl = isValidUrl(url) ? url : DEFAULT_FULL_SCREEN_IMG[level];
    return {
      'background-position': 'center top',
      'background-repeat': 'no-repeat',
      'background-image': `linear-gradient(180deg, rgba(5, 18, 22, 0) 50.86%, rgba(5, 18, 22, 0.8) 73.11%),
                           linear-gradient(0deg, rgba(5, 18, 22, 0) 78.38%, rgba(5, 18, 22, 0.8) 97.37%),
                           linear-gradient(0deg, rgba(5, 18, 22, 0.3), rgba(5, 18, 22, 0.3)),
                           url(${validUrl})`,
    };
  };

  return {
    prize,
    info,
    redeem,
    buildStyle,
  };
};
