import { fetchData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { setPlayer, usePlayer } from '../storage/storage';

const useUpdatePlayer = () => {
  const { getAccessTokenSilently } = useAuth0();
  const player = usePlayer();
  const dispatch = useDispatch();

  const getUrl = `${BACKEND_URL}/player/${player.player_id}`;

  const updatePlayer = async () => {
    try {
      const res = await fetchData(getUrl, getAccessTokenSilently);
      setPlayer(res, dispatch);
    } catch (error) {
      console.error('Error updating player.');
    }
  };

  return { updatePlayer };
};

export { useUpdatePlayer };
