import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useExperience, useInventory } from '../../storage/storage';

import { useCreateUserTrack } from '../../api/UserTrackAPI';

import dailyStreakImg from '../../assets/img/icons/daily-streak.svg';
import correctAnswersImg from '../../assets/img/icons/correct-answers.svg';
import playTimeImg from '../../assets/img/icons/play-time.svg';
import accuracyImg from '../../assets/img/icons/accuracy.svg';
import newTopScoreImg from '../../assets/img/icons/crown.svg';

import '../../i18n/i18n';
import '../../assets/css/game-info.css';
import '../../assets/css/typography.css';
import { secondsToMMSS } from '../../utils/date';

const GameEnded = () => {
  const { stats } = useSelector((state) => state.game);
  const experience = useExperience();
  const inventory = useInventory();
  const { trackAndNavigate } = useCreateUserTrack();

  const { t, i18n } = useTranslation();
  const [modules, setModules] = useState([]);
  const [showNewTopScore, setShowNewTopScore] = useState(false);
  const [newTopScore, setNewTopScore] = useState(false);

  const navigateTo = parseInt(inventory.bonus_available) > 0 ? '/bonus' : '/client';
  const event = parseInt(inventory.bonus_available) > 0 ? 'BONUS_CLICK' : 'ENDGAME_CLICK';

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
    if (!stats) return;
    setNewTopScore(stats.isNewRecord);
    setModules([
      {
        image: dailyStreakImg,
        title: t('game.stats.streak'),
        value: `${stats.streak}`,
      },
      {
        image: accuracyImg,
        title: t('game.stats.accuracy'),
        value: `${stats.accuracy}%`,
      },
      {
        image: playTimeImg,
        title: t('game.stats.timePlayed'),
        value: secondsToMMSS(stats.playedTime),
      },
      {
        image: correctAnswersImg,
        title: t('game.stats.correctAnswers'),
        value: stats.score,
      },
    ]);
  }, [i18n, setModules]);

  if (!stats) return <Navigate to="/client" />;

  const handleButton = () => {
    if (!newTopScore || (newTopScore && showNewTopScore)) return trackAndNavigate(navigateTo, event);

    setShowNewTopScore(true);
  };

  const buildStats = () => {
    if (showNewTopScore) {
      return (
        <>
          <div className="stats-item-placeholder">
            <img src={newTopScoreImg} alt="top" />
            <span>{t('game.stats.newTopScore')}</span>
          </div>
          <div className="stats-item highlighted">
            <div className="stats-item-icon">
              <img src={accuracyImg} alt="icon" />
            </div>
            <div className="stats-item-info">
              <span className="stats-item-info-value">{modules[1].value}</span>
              <span className="stats-item-info-title">{t('game.stats.accuracy')}</span>
            </div>
          </div>
          <div className="stats-item-placeholder"></div>
          <div className="stats-item-placeholder"></div>
        </>
      );
    }

    return modules.map((item, idx) => (
      <div key={idx} className="stats-item">
        <div className="stats-item-icon">
          <img src={item.image} alt="icon" />
        </div>
        <div className="stats-item-info">
          <span className="stats-item-info-value">{item.value}</span>
          <span className="stats-item-info-title">{item.title}</span>
        </div>
      </div>
    ));
  };

  return (
    <div className="client-app">
      <main>
        <div className="game-info">
          <div className="last-game-stats">
            <div className="game-info-title">
              <h1>{t('game.end.accomplished')}</h1>
              <span>{experience.name}</span>
            </div>
            <div className="info-pills">
              <div>
                <span>
                  <strong>{t('game.stats.score')}</strong> {stats.score}/{stats.answered}
                </span>
              </div>
              <div>
                <span>
                  <strong>{t('game.stats.accuracy')}</strong> {stats.accuracy}%
                </span>
              </div>
            </div>
          </div>
          <div className="overall-stats">{buildStats()}</div>
          <div className="action-button">
            <button className="gali-button" onClick={() => handleButton()}>
              {t('game.end.next')}
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default GameEnded;
