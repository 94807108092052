import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useActor } from '../storage/storage';

const useCreateUserTrack = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const actor = useActor();

  const createUserTrack = async (userTrack) => {
    try {
      await postData(`${BACKEND_URL}/user-track`, userTrack, getAccessTokenSilently);
    } catch (error) {
      console.error('Unexpected error occurred while creating the user track.');
      const errorMessage = error.response?.data?.message || 'Unexpected error occurred while creating the user track.';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const trackAndNavigate = async (path, event) => {
    try {
      await createUserTrack({
        companyId: actor.company_id,
        actorId: actor.actor_id,
        event: event,
      });
    } catch (error) {
      console.error('Error tracking user navigation:', error);
    } finally {
      navigate(path);
    }
  };

  return { error, loading, createUserTrack, trackAndNavigate };
};

export { useCreateUserTrack };
