import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Alert, Label, Col, Input, Row } from 'reactstrap';
import Loading from '../../components/Loading';
import { Navigate } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import ReturnButton from '../../components/ReturnButton';
import { useActor } from '../../storage/storage';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useCreateMetric } from '../../api/CompanyAPI';
import { DECIMAL_REGEXP } from '../../utils/constants';

const NewMetric = () => {
  const actor = useActor();
  const { createMetric } = useCreateMetric();
  const [errorForm, setErrorForm] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    levels: {
      0: '',
      1: '',
      2: '',
    },
  });

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const handleInputChange = (event) => {
    const { name, value, dataset } = event.target;
    if (name !== 'levels') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    const index = dataset.index;

    if (DECIMAL_REGEXP.test(value)) {
      setFormData((prevData) => ({
        ...prevData,
        levels: {
          ...prevData.levels,
          [index]: value,
        },
      }));
    } else {
      event.preventDefault();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const error = validateForm();
    if (error) {
      setErrorForm(error);
      return;
    }
    try {
      const formWithId = { ...formData, id: uuidv4() };
      await createMetric(actor.company_id, formWithId);
      setRedirect(true);
    } catch (error) {
      setErrorForm('Error submitting the form:', error.message);
    }
  };

  const validateForm = () => {
    if (!formData.name) return 'The name is required';
    if (!formData.levels[0]) return 'The bronze level is required';
    if (!formData.levels[1]) return 'The silver level is required';
    if (!formData.levels[2]) return 'The gold level is required';
    if (parseFloat(formData.levels[0]) >= parseFloat(formData.levels[1])) return 'Bronze must be lower than silver';
    if (parseFloat(formData.levels[0]) >= parseFloat(formData.levels[2])) return 'Bronze must be lower than gold';
    if (parseFloat(formData.levels[1]) >= parseFloat(formData.levels[2])) return 'Silver must be lower than gold';
    return null;
  };

  if (redirect) return <Navigate to="/metrics" />;

  const messageError = errorForm;

  return (
    <>
      {messageError && <Alert color="danger">{messageError}</Alert>}
      <h2 className="admin-text">Create new Metric</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          <FormGroup>
            <Label className="admin-text" for="name" sm={2}>
              {t('form.name')}
            </Label>
            <Col sm={6}>
              <Input id="name" name="name" placeholder={t('form.name')} type="text" onChange={handleInputChange} />
            </Col>
          </FormGroup>
        </Row>
        <Row>
          {[t('game.catalogue.bronze'), t('game.catalogue.silver'), t('game.catalogue.gold')].map((level, index) => (
            <Col sm={2} key={level}>
              <FormGroup>
                <Label className="admin-text" for={`level-${index}`}>
                  {level}
                </Label>
                <Input
                  id={`level-${index}`}
                  name="levels"
                  data-index={index}
                  type="text"
                  value={formData.levels[index]}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          ))}
        </Row>
        <FormGroup row>
          <Col sm={6}>
            <ReturnButton to={'/metrics'} />
            <Button color="primary" type="submit">
              {t('buttons.add')}
            </Button>
          </Col>
        </FormGroup>
      </Form>
    </>
  );
};
//TODO: Use i18n
export default withAuthenticationRequired(NewMetric, {
  onRedirecting: () => <Loading />,
});
