import { useCallback, useEffect, useState } from 'react';
import { useCreatePrize, useFetchPrizeById, useUpdatePrize } from '../api/PrizesAPI';
import { validateImageFile } from '../utils/componentUtil';
import { useActor } from '../storage/storage';

const usePrizeForm = (id) => {
  const { getPrizeById } = useFetchPrizeById();
  const { updatePrize } = useUpdatePrize();
  const { createPrize } = useCreatePrize();
  const [prize, setPrize] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const actor = useActor();

  useEffect(() => {
    const fetchPrize = async () => {
      if (!id) return;
      try {
        const prizeData = await getPrizeById(id);
        setPrize(prizeData);
      } catch (err) {
        setError(err.message || 'Failed to fetch prize data.');
      }
    };

    fetchPrize();
  }, [id, getPrizeById]);

  const handlePrizeInput = useCallback((event) => {
    const { name, value } = event.target;
    setPrize((prevPrize) => ({
      ...prevPrize,
      [name]: value,
    }));
  }, []);

  const handleImage = useCallback((event) => {
    const file = event.target.files[0];
    const validationError = validateImageFile(file);
    if (validationError) {
      setImageFile(null);
      setError(validationError);
    } else {
      setImageFile(file);
      setError(null);
    }
  }, []);

  const validatePrize = useCallback(() => {
    if (!id && !imageFile) return 'Image file is required.';
    if (!prize || !prize.name) return 'Prize name is required.';
    if (prize.level === undefined || prize.level === null) return 'Level is required.';
    if (!prize.total_pieces) return 'Pieces are required.';
    if (!prize.stock) return 'Stock is required';
  }, [prize, imageFile, id]);

  const mapToFormData = useCallback(() => {
    const formData = new FormData();
    formData.append('name', prize.name);
    formData.append('companyId', actor?.company_id);
    formData.append('total_pieces', prize.total_pieces);
    formData.append('level', prize.level);
    formData.append('image', imageFile);
    formData.append('stock', prize.stock);
    if (prize.description) formData.append('description', prize.description);
    return formData;
  }, [prize, imageFile, actor?.company_id]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setError(null);

      setLoading(true);
      const validationError = validatePrize();
      if (validationError) {
        setError(validationError);
        setLoading(false);
        return;
      }
      const newPrize = mapToFormData();
      try {
        if (id) {
          await updatePrize(id, newPrize);
        } else {
          await createPrize(newPrize);
        }
        setRedirect(true);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [validatePrize, mapToFormData, createPrize, updatePrize, id]
  );

  return { prize, imageFile, error, loading, redirect, handleSubmit, handlePrizeInput, handleImage };
};

export default usePrizeForm;
