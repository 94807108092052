import { useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { NavLink, useNavigate } from 'react-router-dom';

import { Table, Alert, Button, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Loading from '../../components/Loading';
import ReturnButton from '../../components/ReturnButton';
import { useActor } from '../../storage/storage';
import { useTranslation } from 'react-i18next';

import '../../i18n/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useDeleteMetric, useFetchMetrics } from '../../api/CompanyAPI';

const MetricsList = () => {
  const actor = useActor();
  const { metrics, loading, error: fetchMetricsError, fetchMetrics } = useFetchMetrics();
  const { error: deleteMetricError, deleteMetric } = useDeleteMetric();
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [modal, setModal] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    if (actor.company_id) fetchMetrics(actor.company_id);
  }, [fetchMetrics, actor.company_id]);

  const handleDeleteMetric = async () => {
    if (selectedMetric) {
      await deleteMetric(actor.company_id, selectedMetric.id);
      setModal(!modal);
      await fetchMetrics(actor.company_id);
    }
  };

  const toggle = () => setModal(!modal);

  const openDeleteModal = (metric) => {
    setSelectedMetric(metric);
    setModal(!modal);
  };

  const errorMessage = fetchMetricsError || deleteMetricError;

  if (loading) return <Loading />;

  return (
    <div>
      {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{t('menu.metrics')}</h3>
        </CardHeader>
        <CardBody>
          <ButtonGroup>
            <ReturnButton to={'/landing'} />
            <NavLink className="btn btn-primary" to="new">
              {t('buttons.add')}
            </NavLink>
          </ButtonGroup>
          <Table hover borderless responsive size="sm" striped className="text-center">
            <thead>
              <tr>
                <th>{t('tables.name')}</th>
                <th>{t('game.catalogue.bronze')}</th>
                <th>{t('game.catalogue.silver')}</th>
                <th>{t('game.catalogue.gold')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {metrics.length !== 0 &&
                metrics.map((metric) => (
                  <tr key={metric.id}>
                    <td>{metric.name}</td>
                    {Object.keys(metric.levels).map((level) => (
                      <td>{metric.levels[level]}</td>
                    ))}
                    <td>
                      <Button color="primary" size="sm" title="Edit" onClick={() => navigate(`${metric.id}/edit`)}>
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </Button>
                      <Button color="danger" size="sm" title="Delete" onClick={() => openDeleteModal(metric)}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                    </td>
                  </tr>
                ))}
              {metrics.length === 0 && (
                <tr>
                  <td colSpan={4}>No metrics have been created yet</td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle} style={{ color: 'black' }}>
          Confirm Delete
        </ModalHeader>
        <ModalBody style={{ color: 'black' }}>
          {selectedMetric ? `Are you sure to remove the metric "${selectedMetric.name}"?` : ''}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDeleteMetric}>
            Delete Metric
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

//TODO: Use i18n
export default withAuthenticationRequired(MetricsList, {
  onRedirecting: () => <Loading />,
});
