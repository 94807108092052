import bronzeCatalogDefault from '../assets/img/default/bronze-catalog.jpg';
import silverCatalogDefault from '../assets/img/default/silver-catalog.jpg';
import goldCatalogDefault from '../assets/img/default/gold-catalog.jpg';

import bronzeFullScreenDefault from '../assets/img/default/bronze-fullscreen.jpg';
import silverFullScreenDefault from '../assets/img/default/silver-fullscreen.jpg';
import goldFullScreenDefault from '../assets/img/default/gold-fullscreen.jpg';

const SUPPORTED_LANGUAGES = ['en', 'es', 'fr'];

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const POINTS_FOR_BONUS = 25;

const LEVEL_OPTIONS = [
  { value: '0', label: 'game.catalogue.bronze' },
  { value: '1', label: 'game.catalogue.silver' },
  { value: '2', label: 'game.catalogue.gold' },
];

const CATALOGUE_LEVEL_OPTIONS = [
  'game.catalogue.name.bronze',
  'game.catalogue.name.silver',
  'game.catalogue.name.gold',
];

const LEVEL_COLORS = ['bg-bronze', 'bg-silver', 'bg-gold'];
const LEVEL_NAMES = LEVEL_OPTIONS.map((e) => e.label);

const getLevelLabel = function (value) {
  return LEVEL_OPTIONS.find((e) => parseInt(e.value) === value)?.label;
};

const getCatalogueLevelLabel = (value) => CATALOGUE_LEVEL_OPTIONS[value];

const PIECE_OPTIONS = [
  { value: '4', label: '4' },
  { value: '6', label: '6' },
];

const CHEST_AVAILABLE_TABS = {
  DAILY_CHEST: 'DAILY_CHEST',
  CHEST_CONTENT: 'CHEST_CONTENT',
};

const DEFAULT_CATALOG_IMG = {
  0: bronzeCatalogDefault,
  1: silverCatalogDefault,
  2: goldCatalogDefault,
};

const DEFAULT_FULL_SCREEN_IMG = {
  0: bronzeFullScreenDefault,
  1: silverFullScreenDefault,
  2: goldFullScreenDefault,
};

const DEFAULT_LOTTERY = {
  name: 'game.lottery.default.name',
  url: '',
  description: 'game.lottery.default.desc',
  requirements: 'game.lottery.default.requirements',
  drawnByDate: ' game.lottery.default.drawnByDate',
};

const DECIMALS_REGEXP = /^[1-9|0]+\.?[0-9]*$/;

const DECIMAL_REGEXP = /^\d*(\.\d{0,1})?$/;

const PRIZE_REDEMPTION_CLASS_NAMES = {
  base: 'gali-button',
  primary: 'primary',
  locked: 'locked',
  unavailable: 'unavailable',
};

const PRIZE_REDEMPTION_TEXT_KEYS = {
  locked: 'game.catalogue.locked',
  missingPieces: 'game.redemption.missing',
  soldOut: 'game.redemption.soldOut',
  redeemed: 'game.redemption.redeemed',
  redeem: 'game.redemption.redeem',
};

const QUESTION_PRIORITY_TEXT = [
  'content.priority.levels.low',
  'content.priority.levels.medium',
  'content.priority.levels.high',
];

const QUESTION_PRIORITY_COLORS = ['question-priority-low', 'question-priority-medium', 'question-priority-high'];

const QUESTION_DIFFICULTY = [
  'content.difficulty.levels.easy',
  'content.difficulty.levels.medium',
  'content.difficulty.levels.hard',
];

const QUESTION_DIFFICULTY_COLORS = {
  0: 'blue',
  1: 'yellow',
  2: 'red',
};

const ROLE_OPTIONS = ['PLAYER', 'LEADER'];
const LANG_OPTIONS = ['en', 'es', 'fr'];

export {
  getLevelLabel,
  getCatalogueLevelLabel,
  BACKEND_URL,
  LEVEL_OPTIONS,
  LEVEL_NAMES,
  LEVEL_COLORS,
  PIECE_OPTIONS,
  CHEST_AVAILABLE_TABS,
  POINTS_FOR_BONUS,
  DEFAULT_CATALOG_IMG,
  DEFAULT_FULL_SCREEN_IMG,
  DEFAULT_LOTTERY,
  DECIMALS_REGEXP,
  SUPPORTED_LANGUAGES,
  PRIZE_REDEMPTION_CLASS_NAMES,
  PRIZE_REDEMPTION_TEXT_KEYS,
  QUESTION_PRIORITY_TEXT,
  QUESTION_PRIORITY_COLORS,
  QUESTION_DIFFICULTY,
  QUESTION_DIFFICULTY_COLORS,
  ROLE_OPTIONS,
  LANG_OPTIONS,
  DECIMAL_REGEXP,
};
