import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import '../i18n/i18n';
import '../assets/css/index.css';

const Init = () => {
  const { loginWithRedirect } = useAuth0();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const login = async () => {
    const appState = {
      messageId: searchParams.get('m'),
      returnTo: '/landing',
    };

    await loginWithRedirect({ appState });
  };

  return (
    <div className="text-center Init my-5">
      <h1 className="mb-4">Galilei Learning App</h1>

      <p className="lead">{t('init')}.</p>
      <Button onClick={login} color="primary">
        {t('buttons.login')}
      </Button>
    </div>
  );
};

export default Init;
