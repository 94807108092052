import { useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { NavLink, useNavigate } from 'react-router-dom';

import { Table, FormGroup, Alert, Button, ButtonGroup, Input } from 'reactstrap';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { DECIMAL_REGEXP, LEVEL_COLORS, LEVEL_NAMES } from '../../utils/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup, faRankingStar } from '@fortawesome/free-solid-svg-icons';

import Loading from '../../components/Loading';
import ReturnButton from '../../components/ReturnButton';
import { useActor } from '../../storage/storage';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useFetchTeamsByCompany, useUpdateMetricValue } from '../../api/TeamAPI';

const TeamList = () => {
  const { t, i18n } = useTranslation();
  const { teams, loading, error: fetchTeamsByCompanyError, fetchTeamsByCompany } = useFetchTeamsByCompany();
  const { updateMetricValue } = useUpdateMetricValue();
  const [inputValues, setInputValues] = useState({});
  const actor = useActor();
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    if (actor.company_id) fetchTeamsByCompany(actor.company_id);
  }, [actor.company_id, fetchTeamsByCompany]);

  useEffect(() => {
    if (!teams) return;
    const initialValues = teams.reduce((acc, team) => {
      acc[team.team_id] = team.metric_value;
      return acc;
    }, {});
    setInputValues(initialValues);
  }, [teams]);

  const handleInputChange = (teamId, e) => {
    const inputValue = e.target.value;
    if (DECIMAL_REGEXP.test(inputValue)) {
      setInputValues((prev) => ({ ...prev, [teamId]: inputValue }));
    } else {
      e.preventDefault();
    }
  };

  const handleButtonClick = async (teamId) => {
    await updateMetricValue(teamId, inputValues[teamId]);
    await fetchTeamsByCompany(actor.company_id);
  };

  if (loading) return <Loading />;

  const errorMessage = fetchTeamsByCompanyError;

  return (
    <div>
      {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{t('menu.myTeam')}</h3>
        </CardHeader>
        <CardBody>
          <ButtonGroup>
            <ReturnButton to={'/landing'} />
            <NavLink className="btn btn-primary" to="new">
              {t('buttons.add')}
            </NavLink>
          </ButtonGroup>
          <FormGroup row>{!teams && <Alert color="light">{t('tables.empty')}</Alert>}</FormGroup>

          {teams && (
            <Table hover borderless responsive size="sm" striped className="text-center">
              <thead>
                <tr>
                  <th>{t('tables.name')}</th>
                  <th>{t('catalogue.level')}</th>
                  <th>Metric Name</th>
                  <th style={{ width: '200px' }}>Metric Value</th>
                  <th colSpan={2}>{t('tables.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {teams.map((team) => (
                  <tr key={team.name}>
                    <td>{team.name}</td>
                    <td>
                      <span className={`badge ${LEVEL_COLORS[team.prize_level]}`}>
                        {t(LEVEL_NAMES[team.prize_level])}
                      </span>
                    </td>
                    <td>{team.metricDetails ? team.metricDetails.name : 'unassigned'}</td>
                    <td>
                      {team.metricDetails ? (
                        <div className="d-flex align-items-center">
                          <Input
                            type="text"
                            min="0"
                            className="me-1"
                            value={inputValues[team.team_id]}
                            onChange={(e) => handleInputChange(team.team_id, e)}
                          />
                          <Button color="primary" onClick={() => handleButtonClick(team.team_id)}>
                            {t('buttons.ok')}
                          </Button>
                        </div>
                      ) : (
                        team.metric_value
                      )}
                    </td>
                    <td>
                      <Button color="link" title="See players" size="sm" onClick={() => navigate(`${team.team_id}`)}>
                        <FontAwesomeIcon icon={faPeopleGroup} className="faWhite" />
                      </Button>
                      {!team.metricDetails && (
                        <Button
                          color="link"
                          title="Assign metric"
                          size="sm"
                          onClick={() => navigate(`${team.team_id}/assign-metric`)}
                        >
                          <FontAwesomeIcon icon={faRankingStar} className="faWhite" />
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
//TODO: Use i18n
export default withAuthenticationRequired(TeamList, {
  onRedirecting: () => <Loading />,
});
