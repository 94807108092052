import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  game: null,
  isPlayed: false,
  stats: null,
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    resumeGame: (state, action) => {
      state.game = action.payload.game;
      state.isPlayed = action.payload.isPlayed;
    },
    initGame: (state, action) => {
      state.game = action.payload;
      state.isPlayed = false;
      state.stats = null;
    },
    resetGame: (state) => {
      state.game = null;
      state.isPlayed = false;
      state.stats = null;
    },
    markAsPlayed: (state) => {
      state.isPlayed = true;
    },
    finishGame: (state, action) => {
      state.isPlayed = true;
      state.stats = action.payload;
    },
  },
});

export const { resumeGame, initGame, resetGame, markAsPlayed, finishGame } = gameSlice.actions;
export const selectGame = (state) => state.game;
export default gameSlice.reducer;
