import { QUESTION_PRIORITY_TEXT, QUESTION_PRIORITY_COLORS } from '../utils/constants';
import React, { useEffect } from 'react';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';

const QuestionPriorityBadge = ({ priority }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);
  return <span className={`badge ${QUESTION_PRIORITY_COLORS[priority]}`}>{t(QUESTION_PRIORITY_TEXT[priority])}</span>;
};

export default QuestionPriorityBadge;
