import React from 'react';
import Loading from '../../components/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import InviteActor from './InviteActor';

const InviteLeader = () => <InviteActor role="LEADER" />;

export default withAuthenticationRequired(InviteLeader, {
  onRedirecting: () => <Loading />,
});
