import { useEffect } from 'react';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';

const AlertMessage = ({ membership }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  return (
    <>
      {membership.hasCapacity && (
        <Alert>
          <h4 className="alert-heading">{t('invitation.ready.title')}</h4>
          <p>{t('invitation.ready.desc')}</p>
          <ul>
            <li>
              <b>{t('invitation.ready.slots')}</b> {membership.capacity}
            </li>
          </ul>
          <hr />
          <p className="mb-0">{t('invitation.ready.footer')}</p>
        </Alert>
      )}

      {!membership.hasCapacity && (
        <Alert color="danger">
          <h4 className="alert-heading">{t('invitation.full.title')}</h4>
          <p>
            {t('invitation.full.body1')} {membership.invitation_capacity} {t('invitation.full.body2')}
          </p>
          <hr />
          <p className="mb-0">{t('invitation.full.footer')}</p>
        </Alert>
      )}
    </>
  );
};

export default AlertMessage;
