import { useEffect } from 'react';
import { Badge, Button, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { useSendNotification } from '../../api/MembershipAPI';

import '../../i18n/i18n';

const MembersTable = ({ memberships, actionError }) => {
  const { t, i18n } = useTranslation();
  const { sendNotification, error } = useSendNotification();
  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const handleSendInvitation = async (membershipId) => {
    actionError(null);
    await sendNotification(membershipId);
    actionError(error);
  };

  return (
    <Table hover borderless responsive size="sm" striped className="text-center">
      <thead>
        <tr>
          <th>Phone</th>
          <th>Team</th>
          <th>Lang</th>
          <th>Role</th>
          <th>Status</th>
          <th>Actions</th>
          <th>Notification</th>
        </tr>
      </thead>
      <tbody>
        {memberships &&
          memberships.map((membership) => (
            <tr key={membership?.membership_id}>
              <td>{membership?.phone_number}</td>
              <td>{membership?.team_name}</td>
              <td>{membership?.lang}</td>
              <td>{membership?.role}</td>
              <td>
                {membership?.accepted ? (
                  <Badge color="success" pill>
                    Accepted
                  </Badge>
                ) : (
                  <Badge color="danger" pill>
                    Pending
                  </Badge>
                )}
              </td>
              <td>
                <Button
                  color="primary"
                  onClick={() => {
                    handleSendInvitation(membership.membership_id);
                  }}
                >
                  {t('invitation.send')}
                </Button>
              </td>
              <td>
                <Badge>{membership.notification_state?.toUpperCase()}</Badge>
              </td>
            </tr>
          ))}
        {memberships.length === 0 && (
          <tr>
            <td colSpan="5">No memberships have been notified yet</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
export default MembersTable;
