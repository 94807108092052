import Loading from '../components/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Alert,
  Label,
  Col,
  Input,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  Row,
} from 'reactstrap';
import ReturnButton from '../components/ReturnButton';
import { LEVEL_OPTIONS, PIECE_OPTIONS } from '../utils/constants';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';
import usePrizeForm from '../hooks/usePrizeForm.js';

const PrizeForm = () => {
  const { id } = useParams();
  const { prize, imageFile, error, loading, redirect, handlePrizeInput, handleImage, handleSubmit } = usePrizeForm(id);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  if (redirect) return <Navigate to="/prize" />;

  return (
    <>
      {error && <Alert color="danger">{error}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{id ? t('catalogue.edit') : t('catalogue.add')}</h3>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={5}>
                <Card id="prizeCard" style={{ width: '26rem' }}>
                  <CardHeader>
                    <FormGroup>
                      <Label className="admin-text" for="image">
                        {t('form.img')}
                      </Label>
                      <Input type="file" name="image" accept="image/*" id="image" onChange={handleImage} />
                    </FormGroup>
                  </CardHeader>
                  <CardBody>
                    <CardImg
                      id="image"
                      src={imageFile ? URL.createObjectURL(imageFile) : prize?.url}
                      alt="Imagen premio"
                      style={{
                        width: '24rem',
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <FormGroup floating>
                  <Input id="name" name="name" type="text" value={prize?.name || ''} onChange={handlePrizeInput} />
                  <Label className="admin-text" for="name">
                    {t('form.name')}
                  </Label>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    id="level"
                    name="level"
                    type="select"
                    defaultValue={-1}
                    value={prize?.level ?? ''}
                    onChange={handlePrizeInput}
                  >
                    <option />
                    {LEVEL_OPTIONS.map((o) => (
                      <option key={o.value} value={o.value}>
                        {t(o.label)}
                      </option>
                    ))}
                  </Input>
                  <Label className="admin-text" for="level">
                    {t('catalogue.level')}
                  </Label>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    id="pieces"
                    name="total_pieces"
                    placeholder={t('catalogue.pieces')}
                    type="select"
                    value={prize?.total_pieces || ''}
                    onChange={handlePrizeInput}
                  >
                    <option />
                    {PIECE_OPTIONS.map((o) => (
                      <option key={o.value} value={o.value}>
                        {o.label}
                      </option>
                    ))}
                  </Input>
                  <Label className="admin-text" for="pieces">
                    {t('catalogue.pieces')}
                  </Label>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    id="description"
                    name="description"
                    type="textarea"
                    value={prize?.description || ''}
                    onChange={handlePrizeInput}
                    style={{
                      height: '8rem',
                      resize: 'none',
                    }}
                  />
                  <Label className="admin-text" for="description">
                    {t('form.desc')}
                  </Label>
                </FormGroup>
                <FormGroup floating>
                  <Input id="stock" name="stock" type="number" value={prize?.stock || ''} onChange={handlePrizeInput} />
                  <Label className="admin-text" for="stock">
                    {t('catalogue.stock')}
                  </Label>
                </FormGroup>
                <FormGroup className="my-2">
                  <ReturnButton to={'/prize'} />
                  <Button color="primary" type="submit" disabled={loading}>
                    {t('buttons.ok')}
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default withAuthenticationRequired(PrizeForm, {
  onRedirecting: () => <Loading />,
});
