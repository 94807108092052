import React, { useEffect } from 'react';

import '../../assets/css/profile/metrics.css';
import { useTeam } from '../../storage/storage';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import PrizeBadgesRibbon from './PrizeBadgesRibbon';
import { Navigate } from 'react-router-dom';
import MetricLevels from './MetricLevels';

const PrizeLevel = () => {
  const team = useTeam();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  if (!team) return <Navigate to="/" />;

  const currentPrizeLevel = team.prize_level;

  return (
    <>
      <div className="metrics-title-container">
        <span className="metrics-title">{t('game.profile.level.name')}</span>
      </div>
      <PrizeBadgesRibbon level={currentPrizeLevel} />
      <MetricLevels level={currentPrizeLevel} />
    </>
  );
};

export default PrizeLevel;
