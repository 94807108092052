// External Libraries
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row, Table, Button, Alert } from 'reactstrap';
import { useParams, useNavigate } from 'react-router-dom';

// Assets (Fonts, Images, CSS, SFX)
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import ReturnButton from '../../components/ReturnButton';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';
import { QUESTION_DIFFICULTY } from '../../utils/constants';
import QuestionPriorityBadge from '../../components/QuestionPriorityBadge';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useExperienceForm from '../../hooks/useExperienceForm';

const ExperienceQuestions = () => {
  const { id: experienceId } = useParams();
  const { experience, error, loading } = useExperienceForm(experienceId);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
    setLanguage(i18n.resolvedLanguage);
  }, [i18n, setLanguage]);

  const availableLanguage = (text) => {
    if (!text && text !== 0) return 'Content not available in your language...';
    return text;
  };

  if (loading) return <Loading />;

  return (
    <>
      {error && <Alert color="danger">{error}</Alert>}
      <Container>
        <ReturnButton to="/experiences" />
        <Card>
          {experience && (
            <CardHeader tag={'h4'}>
              <Row>
                <Col className="content-left admin-text">
                  {experience.name[i18n.resolvedLanguage]}&nbsp;({experience.questions.length})
                </Col>
                <Col className="content-right">
                  {experience.active ? (
                    <div className="text-enabled">{t('content.active')}</div>
                  ) : (
                    <div className="text-disabled">{t('content.inactive')}</div>
                  )}
                </Col>
              </Row>
            </CardHeader>
          )}
          <CardBody>
            {experience && (
              <Table striped>
                <thead>
                  <tr>
                    <th>{t('content.question')}</th>
                    <th>{t('form.img')}</th>
                    <th>{t('content.answers')}</th>
                    <th>{t('content.correctAns')}</th>
                    <th>{t('content.priority.name')}</th>
                    <th>{t('content.difficulty.name')}</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {experience.questions.map((question) => (
                    <>
                      <tr key={question.id}>
                        {question.text ? (
                          <td>{availableLanguage(question.text[language])}</td>
                        ) : (
                          <td className="text-center">
                            <FontAwesomeIcon icon={faXmark} className="faRed" />
                          </td>
                        )}
                        {question.image ? (
                          <td>
                            <img className="experience" src={question.imageUrl} alt="" />
                          </td>
                        ) : (
                          <td className="text-center">
                            <FontAwesomeIcon icon={faXmark} className="faRed" />
                          </td>
                        )}
                        <td>
                          {question.options.map((option) => (
                            <div key={option.id}>- {availableLanguage(option.text[language])}</div>
                          ))}
                        </td>
                        <td>
                          {question.options
                            .filter((option) => option.id === question.validAnswerOptionId)
                            .map((option) => (
                              <div>
                                {option.text[language]}&nbsp;
                                <FontAwesomeIcon icon={faCheck} className="faGreen" />
                              </div>
                            ))}
                        </td>
                        <td>
                          <QuestionPriorityBadge priority={question.priority} />
                        </td>
                        <td>{t(QUESTION_DIFFICULTY[question.difficulty])}</td>
                        <td>
                          <Button
                            key={question.id}
                            color="link"
                            size="sm"
                            className="view-icon"
                            onClick={() =>
                              navigate(`/experiences/${experience.experience_id}/question/${question.id}`, {
                                state: { question, experience },
                              })
                            }
                          >
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </Button>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            )}
            {experience && loading && <Loading />}
            {experience && error && <div>{t('tables.error')}</div>}
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default withAuthenticationRequired(ExperienceQuestions, {
  onRedirecting: () => <Loading />,
});
