import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useState } from 'react';
import { fetchData, postData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';

const useFetchMembershipDetails = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [membership, setMembership] = useState({});

  const fetchMembershipDetails = useCallback(
    async (id) => {
      try {
        const data = await fetchData(`${BACKEND_URL}/company/${id}/membership-details`, getAccessTokenSilently);
        setMembership(data);
      } catch (error) {
        console.error('Unexpected error occurred while getting the membership details.');
        const errorMessage =
          error.response?.data?.message || 'Unexpected error occurred while getting the membership details.';
        setError(errorMessage);
        throw new Error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );
  return { membership, error, loading, fetchMembershipDetails };
};

const useCreateMembership = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const createMembership = async (membership) => {
    try {
      await postData(`${BACKEND_URL}/membership`, membership, getAccessTokenSilently);
    } catch (error) {
      console.error('Unexpected error occurred while creating the membership.');
      const errorMessage = error.response?.data?.message || 'Unexpected error occurred while creating the membership.';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { error, loading, createMembership };
};

const useMembershipsByCompany = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [memberships, setMemberships] = useState([]);

  const fetchMembershipsByCompany = useCallback(
    async (id) => {
      try {
        const { data } = await fetchData(`${BACKEND_URL}/company/${id}/membership`, getAccessTokenSilently);
        setMemberships(data);
      } catch (error) {
        console.error('Error fetching memberships.');
        const errorMessage = error.response?.data?.message || 'Unexpected error occurred while fetching memberships.';
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );
  return { memberships, error, loading, fetchMembershipsByCompany };
};

const useFetchMembership = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [membership, setMembership] = useState({});

  const fetchMembership = useCallback(
    async (id) => {
      try {
        const data = await fetchData(`${BACKEND_URL}/membership/${id}`, getAccessTokenSilently);
        setMembership(data);
      } catch (error) {
        console.error('Unexpected error occurred while getting the membership.');
        const errorMessage = error.response?.data?.message || 'Unexpected error occurred while getting the membership.';
        setError(errorMessage);
        throw new Error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );
  return { membership, error, loading, fetchMembership };
};

const useAcceptMembership = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const acceptMembership = async (id, membership) => {
    try {
      return await postData(`${BACKEND_URL}/membership/${id}`, membership, getAccessTokenSilently);
    } catch (error) {
      console.error('Unexpected error occurred while accepting membership.');
      const errorMessage = error.response?.data?.message || 'Unexpected error occurred while accepting membership.';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { error, loading, acceptMembership };
};

const useSendNotification = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const sendNotification = async (id) => {
    setLoading(true);
    setError(null);
    try {
      return await postData(`${BACKEND_URL}/membership/${id}/notification`, {}, getAccessTokenSilently);
    } catch (error) {
      console.error('Unexpected error occurred while sending the notification.');
      const errorMessage = error.response?.data?.message || 'Unexpected error occurred while sending the notification.';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { error, loading, sendNotification };
};

export {
  useFetchMembershipDetails,
  useCreateMembership,
  useMembershipsByCompany,
  useFetchMembership,
  useAcceptMembership,
  useSendNotification,
};
