import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { useActor, useAppAuthenticated } from '../storage/storage.js';
import { useCreateUserTrack } from '../api/UserTrackAPI';
import { hasRole } from '../utils/roleUtil';

import Loading from '../components/Loading.js';
import Stats from './profile/Stats';
import PrizeLevel from './profile/PrizeLevel';
import '../i18n/i18n';
import '../assets/css/perfil.css';
import '../assets/css/profile.css';
import avatar from '../assets/img/profile/avatar.svg';

const PlayerProfile = () => {
  const { createUserTrack } = useCreateUserTrack();
  const trackAndNavigate = async (path, event) => {
    try {
      await createUserTrack({
        companyId: actor.company_id,
        actorId: actor.actor_id,
        event: event,
      });
    } catch (error) {
      console.error('Error tracking user navigation:', error);
    } finally {
      navigate(path);
    }
  };

  const actor = useActor();
  const isAppAuthenticated = useAppAuthenticated();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const isLeader = isAppAuthenticated && hasRole(actor, 'LEADER');

  if (!isAppAuthenticated || !actor) return <Navigate to="/" />;

  return (
    <div>
      <div className="filler" />
      <div className="avatar-container">
        <img alt="avatar" src={avatar} />
      </div>
      <div className="name-container">
        <span className="player-name-text">{actor?.full_name}</span>
        {isLeader && (
          <div className="leader-badge">
            <span className="leader-badge-text">{t('game.profile.leader')}</span>
          </div>
        )}
      </div>
      <PrizeLevel />
      <Stats />
      <div className="filler" />
      <div className="sub-container last">
        <button className="gali-button" onClick={() => trackAndNavigate('/logout', 'LOGOUT')}>
          {t('buttons.logout')}
        </button>
      </div>
    </div>
  );
};

export default withAuthenticationRequired(PlayerProfile, {
  onRedirecting: () => <Loading />,
});
