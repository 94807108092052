import React from 'react';

const ALLOWED_COLORS = ['red', 'blue', 'greenyellow'];
const DEFAULT_COLOR = 'white';

const colorText = (input) => {
  if (!input) return;

  const regex = /<(#?[a-zA-Z0-9]+)>(.*?)<\/\1>/g;
  const elements = [];
  let lastIndex = 0;
  let match;

  while ((match = regex.exec(input)) !== null) {
    const [fullMatch, tag, content] = match;

    // Push plain text before the tag
    if (match.index > lastIndex) {
      elements.push(input.substring(lastIndex, match.index));
    }

    let style = {};
    let Wrapper = 'span';

    // Handle hex colors and named colors
    if (/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(tag) || ALLOWED_COLORS.includes(tag.toLowerCase())) {
      style.color = tag;
    } else if (tag.toLowerCase() === 'b') {
      Wrapper = 'strong';
    } else if (tag.toLowerCase() === 'i') {
      Wrapper = 'em';
    } else {
      style.color = DEFAULT_COLOR; // Fallback for unknown tags
    }

    // Push styled/structured text
    elements.push(
      <Wrapper key={match.index} style={style}>
        {colorText(content)} {/* Recursive parsing for nested tags */}
      </Wrapper>
    );

    lastIndex = regex.lastIndex;
  }

  // Push remaining text
  if (lastIndex < input.length) {
    elements.push(input.substring(lastIndex));
  }

  return elements;
};

export { colorText };
