const HIGH_PRIORITY = 2;
const MEDIUM_PRIORITY = 1;
const LOW_PRIORITY = 0;
const DEFAULT_PRIORITY = 1;
const DEFAULT_DIFFICULTY = 1;

const PROBABILITY = {
  2: 0.5,
  1: 0.8,
  0: 1,
};

const shuffle = (input) => {
  if (!input) return [];
  const array = input.slice();
  let currentIndex = array.length;
  while (currentIndex !== 0) {
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
  return array;
};

const getComponents = (questions) => {
  const sortedQuestions = getSortedQuestionsByShowedCount(questions);
  const nextQuestion = getNextQuestion(sortedQuestions);
  const questionsToComplete = questions.filter((q) => q.id !== nextQuestion.id);
  const newQuestions = [...questionsToComplete, nextQuestion];
  return {
    questions: newQuestions,
    question: nextQuestion,
  };
};

const getSortedQuestionsByShowedCount = (questions) => {
  let isQuestionsToShow = false;
  let questionsByPriority = [];
  while (!isQuestionsToShow) {
    const priority = getPriority();
    questionsByPriority = getQuestionsByPriority(questions, priority);
    isQuestionsToShow = questionsByPriority.length > 0;
  }
  return questionsByPriority.sort((a, b) => a.showedCount - b.showedCount);
};

const getNextQuestion = (sortedQuestions) => {
  const question = sortedQuestions.shift();
  return {
    ...question,
    showedCount: question.showedCount + 1,
    options: shuffle(question.options),
  };
};

const getQuestionsForGame = (questions) => {
  const questionsForGame = questions.map((question) => {
    return {
      id: question.id,
      text: question.text,
      priority: question.priority ? question.priority : DEFAULT_PRIORITY,
      difficulty: question.difficulty ? question.difficulty : DEFAULT_DIFFICULTY,
      validAnswerOptionId: question.validAnswerOptionId,
      imageUrl: question.imageUrl,
      options: question.options,
      showedCount: 0,
    };
  });
  return shuffle(Array.from(questionsForGame));
};

const getPriority = () => {
  const random = Math.random();
  if (random <= PROBABILITY[HIGH_PRIORITY]) return HIGH_PRIORITY;
  if (random <= PROBABILITY[MEDIUM_PRIORITY]) return MEDIUM_PRIORITY;
  return LOW_PRIORITY;
};

const getQuestionsByPriority = (questions, priority) => {
  return questions.filter((q) => q.priority === priority);
};

export { getComponents, getQuestionsForGame };
