import '../../../assets/css/countdown/spinner.css';

const posX = (angle, radius, centerX) => Math.cos((Math.PI * angle) / 180) * radius + centerX;
const posY = (angle, radius, centerY) => Math.sin((Math.PI * angle) / 180) * radius + centerY;

const LoadingSpinner = () => {
  const innerOrbit = 60;
  const middleOrbit = innerOrbit + 60;
  const outerOrbit = middleOrbit + 60;

  const svgWidth = window.screen.availWidth;
  const svgHeight = window.screen.availHeight;

  const centerX = svgWidth / 2;
  const centerY = svgHeight / 2;

  const viewBox = `0 0 ${svgWidth} ${svgHeight}`;

  const angles = [
    [0, 120, 240],
    [-10, 110, 230],
    [-20, 100, 220],
  ];

  return (
    <svg
      width={`${svgWidth}`}
      height={`${svgHeight}`}
      viewBox={`${viewBox}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect
          className="spinner-background normal-background"
          y="0"
          rx="8"
          width={`${svgWidth}`}
          height={`${svgHeight}`}
        />
        <circle className="orbit-circle" cx={`${centerX}`} cy={`${centerY}`} r={`${innerOrbit}`} />
        <circle className="orbit-circle" cx={`${centerX}`} cy={`${centerY}`} r={`${middleOrbit}`} />
        <circle className="orbit-circle" cx={`${centerX}`} cy={`${centerY}`} r={`${outerOrbit}`} />

        <circle
          className="spinner-circle loading-normal-inner-circle"
          r="5"
          cx={`${posX(angles[0][0], innerOrbit, centerX)}`}
          cy={`${posY(angles[0][0], innerOrbit, centerY)}`}
        />
        <circle
          className="spinner-circle loading-normal-inner-circle"
          r="5"
          cx={`${posX(angles[0][1], innerOrbit, centerX)}`}
          cy={`${posY(angles[0][1], innerOrbit, centerY)}`}
        />
        <circle
          className="spinner-circle loading-normal-inner-circle"
          r="5"
          cx={`${posX(angles[0][2], innerOrbit, centerX)}`}
          cy={`${posY(angles[0][2], innerOrbit, centerY)}`}
        />

        <circle
          className="spinner-circle loading-normal-middle-circle"
          r="5"
          cx={`${posX(angles[1][0], middleOrbit, centerX)}`}
          cy={`${posY(angles[1][0], middleOrbit, centerY)}`}
        />
        <circle
          className="spinner-circle loading-normal-middle-circle"
          r="5"
          cx={`${posX(angles[1][1], middleOrbit, centerX)}`}
          cy={`${posY(angles[1][1], middleOrbit, centerY)}`}
        />
        <circle
          className="spinner-circle loading-normal-middle-circle"
          r="5"
          cx={`${posX(angles[1][2], middleOrbit, centerX)}`}
          cy={`${posY(angles[1][2], middleOrbit, centerY)}`}
        />

        <circle
          className="spinner-circle loading-normal-outer-circle"
          r="5"
          cx={`${posX(angles[2][0], outerOrbit, centerX)}`}
          cy={`${posY(angles[2][0], outerOrbit, centerY)}`}
        />
        <circle
          className="spinner-circle loading-normal-outer-circle"
          r="5"
          cx={`${posX(angles[2][1], outerOrbit, centerX)}`}
          cy={`${posY(angles[2][1], outerOrbit, centerY)}`}
        />
        <circle
          className="spinner-circle loading-normal-outer-circle"
          r="5"
          cx={`${posX(angles[2][2], outerOrbit, centerX)}`}
          cy={`${posY(angles[2][2], outerOrbit, centerY)}`}
        />
      </g>
    </svg>
  );
};

export default LoadingSpinner;
