import Loading from '../components/Loading';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Table, Button, FormGroup, Alert, Card, CardBody, CardHeader, ButtonGroup } from 'reactstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import ReturnButton from '../components/ReturnButton';
import { LEVEL_NAMES as levelNames, BACKEND_URL, LEVEL_COLORS } from '../utils/constants';
import { getSortIcon, handleSort } from './stats/sorting/Sorting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { applyHeaders } from '../api/axiosClient';
import { useActor } from '../storage/storage';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';

const PrizeList = () => {
  const { isLoading, error, getAccessTokenSilently } = useAuth0();
  const actor = useActor();
  const [prizeList, setPrizeList] = useState();
  const navigate = useNavigate();
  const [state, setState] = useState({
    error: null,
  });
  const [sortConfig, setSortConfig] = useState({
    key: 'level',
    direction: 'ascending',
  });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const onSort = (key) => {
    const { sortedData, direction } = handleSort(prizeList, {
      key,
      direction: sortConfig.direction,
    });
    setPrizeList(sortedData);
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    const getPrizeList = async () => {
      const companyId = actor.company_id;
      const headers = await applyHeaders(getAccessTokenSilently);
      await axios
        .get(`${BACKEND_URL}/company/${companyId}/prize`, headers)
        .then((response) => {
          const key = 'level';
          const { sortedData } = handleSort(response.data.data, {
            key,
            direction: sortConfig.direction,
          });
          setPrizeList(sortedData);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setPrizeList(null);
            return;
          }
          setState({
            ...state,
            error: error.response.data.message,
          });
        });
    };
    getPrizeList();
  }, [getAccessTokenSilently, actor, setState, state, sortConfig]);

  if (error) return <p>{error.message}</p>;

  if (isLoading) return <Loading />;

  return (
    <div>
      {state.error && <Alert color="danger">{state.error}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{t('menu.catalogue')}</h3>
        </CardHeader>
        <CardBody>
          <ButtonGroup>
            <ReturnButton to={'/landing'} />
            <NavLink className="btn btn-primary" to="new">
              {t('buttons.add')}
            </NavLink>
          </ButtonGroup>
          <FormGroup row>{!prizeList && <Alert color="light">{t('tables.empty')}</Alert>}</FormGroup>

          {prizeList && (
            <Table hover responsive size="sm" striped className="text-center">
              <thead>
                <tr key="header">
                  <th
                    className="orderable-column"
                    title={t('tables.sort')}
                    onClick={() => onSort('name')}
                    aria-placeholder="Ordenar"
                  >
                    {t('tables.name')} {getSortIcon(sortConfig, 'name')}
                  </th>
                  <th>{t('tables.redeemed')}</th>
                  <th>{t('catalogue.stock')}</th>
                  <th className="orderable-column" title={t('tables.sort')} onClick={() => onSort('level')}>
                    {t('catalogue.level')} {getSortIcon(sortConfig, 'level')}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {prizeList.map((prize) => (
                  <tr key={prize.prize_id}>
                    <td>
                      <span className="text-dark badge">{prize.name}</span>
                    </td>
                    <th>
                      <span className="text-dark badge">{prize.redeemed}</span>
                    </th>
                    <th>
                      <span className="text-dark badge">{prize.stock}</span>
                    </th>
                    <td>
                      <span className={`badge ${LEVEL_COLORS[prize.level]}`}>{t(levelNames[prize.level])}</span>
                    </td>
                    <td>
                      <Button
                        color="link"
                        size="sm"
                        className="view-icon"
                        onClick={() => navigate(`${prize.prize_id}/edit`)}
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default withAuthenticationRequired(PrizeList, {
  onRedirecting: () => <Loading />,
});
