import { v4 as uuidV4 } from 'uuid';
import { useCallback, useEffect, useState } from 'react';
import { validateImageFile } from '../utils/componentUtil';
import { useFetchExperienceById, useUpdateQuestion } from '../api/ExperiencesAPI';
import { QUESTION_DIFFICULTY, QUESTION_PRIORITY_TEXT, SUPPORTED_LANGUAGES } from '../utils/constants';

const MAX_OPTIONS_COUNT = 4;
const MIN_OPTIONS_COUNT = 2;

const useEditQuestion = (questionId, experienceId) => {
  const { updateQuestion } = useUpdateQuestion();
  const { getExperienceById } = useFetchExperienceById();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [question, setQuestion] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    const fetchExperience = async () => {
      if (!experienceId) return;
      setLoading(true);
      try {
        const experience = await getExperienceById(experienceId);
        const questionFound = experience.questions.find((q) => q.id === questionId);
        if (!questionFound) {
          setError('Invalid question');
          return;
        }
        setQuestion(questionFound);
      } catch (err) {
        setError(err.message || 'Failed to fetch experience data.');
      } finally {
        setLoading(false);
      }
    };

    fetchExperience();
  }, [experienceId, getExperienceById]);

  const handleQuestionInput = (event, language) => {
    setQuestion((prevQuestion) => {
      const newQuestion = { ...prevQuestion };
      newQuestion.text[language] = event.target.value;
      return newQuestion;
    });
  };

  const handleAnswerInput = (event, optionId, language) => {
    setQuestion((prevQuestion) => {
      const newQuestion = { ...prevQuestion };
      const index = newQuestion.options.findIndex((o) => o.id === optionId);
      newQuestion.options[index].text[language] = event.target.value;
      return newQuestion;
    });
  };

  const handleCorrectAnswerInput = (event) => {
    setQuestion((prevQuestion) => {
      const nextQuestion = { ...prevQuestion };
      nextQuestion.validAnswerOptionId = event.target.value;
      return nextQuestion;
    });
  };

  const handlePriorityChange = (event) => {
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      priority: QUESTION_PRIORITY_TEXT.indexOf(event.target.value),
    }));
  };

  const handleDifficultyChange = (event) => {
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      difficulty: QUESTION_DIFFICULTY.indexOf(event.target.value),
    }));
  };

  const handleAddQuestionOptions = () => {
    setQuestion((prevQuestion) => {
      if (prevQuestion.options.length >= MAX_OPTIONS_COUNT) {
        return prevQuestion;
      } else {
        const text = {};
        for (const lang of SUPPORTED_LANGUAGES) {
          text[lang] = '';
        }
        return {
          ...prevQuestion,
          options: [...prevQuestion.options, { id: uuidV4(), text }],
        };
      }
    });
  };

  const handleRemoveQuestionOptions = (optionId) => {
    setQuestion((prevQuestion) => {
      if (prevQuestion.options.length <= MIN_OPTIONS_COUNT) {
        return prevQuestion;
      } else {
        return {
          ...prevQuestion,
          options: prevQuestion.options.filter((o) => o.id !== optionId),
        };
      }
    });
  };

  const handleImage = useCallback((event) => {
    const file = event.target.files[0];
    const validationError = validateImageFile(file);
    if (validationError) {
      setImageFile(null);
      setError(validationError);
    } else {
      setImageFile(file);
      setError(null);
    }
  }, []);

  const mapToFormData = useCallback(() => {
    const formData = new FormData();
    formData.append('id', question.id);
    formData.append('text', JSON.stringify(question.text));
    formData.append('count', question.count);
    formData.append('mission', question.mission);
    formData.append('options', JSON.stringify(question.options));
    formData.append('priority', question.priority);
    formData.append('difficulty', question.difficulty);
    formData.append('validAnswerOptionId', question.validAnswerOptionId);
    formData.append('image', question.image);
    if (imageFile) formData.append('newImage', imageFile);
    return formData;
  }, [question, imageFile]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setError(null);
      setLoading(true);

      const questionData = mapToFormData();
      try {
        await updateQuestion(experienceId, questionData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }

      event.preventDefault();
    },
    [updateQuestion, mapToFormData]
  );

  return {
    question,
    imageFile,
    error,
    loading,
    handleQuestionInput,
    handleAnswerInput,
    handleCorrectAnswerInput,
    handlePriorityChange,
    handleDifficultyChange,
    handleAddQuestionOptions,
    handleRemoveQuestionOptions,
    handleImage,
    handleSubmit,
  };
};

export default useEditQuestion;
